import Column from 'components/Column'
import Row from 'components/Row'
import Button from 'components/UI/Button'
import Toggle from 'components/UI/Toggle'
import { useState } from 'react'
import { Template, Title } from '../Styles'
import { Subtitle, Toggles } from './Styles'

export default function AdditionalSettings() {

	const items = ['Все', 'Друзья']
	const [option, setOption] = useState<number>(0)

	return (
		<Template>
			<Column gap='3rem'>
				<Title>
					Дополнительные настройки
				</Title>
				<Column gap='1.5rem' alignItems='flex-start'>
					<Subtitle>
						Личные сообщения могут писать
					</Subtitle>
					<Row>
						{items.map((item, index) =>
							<Button
								className={index === option ? 'active' : ''}
								height='5rem'
								variant='picker'
								onClick={() => setOption(index)}
							>
								{item}
							</Button>
						)}
					</Row>
				</Column>
				<Toggles>
					<Toggle
						label='Уведомления на почту'
					/>
					<Toggle
						label='Привязка cookies к ip адресу'
					/>
				</Toggles>
			</Column>
		</Template>
	)
}
