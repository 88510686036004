import { endpoints, url } from "API";
import axios from "axios";

export class TradeService {
  static async getHistory() {
    try {
      const response = await axios.get(url + endpoints.trade.history, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async cancel(uuid: string) {
    try {
      const response = await axios.post(
        url + endpoints.trade.cancel,
        { uuid },
        { withCredentials: true }
      );
      // console.log("dddddddddddddsdasd", response.data);
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }
}
