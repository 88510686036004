import { Slide, ToastContainer, ToastContainerProps } from "react-toastify";

interface Props {
  params?: ToastContainerProps;
}

export default function Toast({ params }: Props) {
  return (
    <ToastContainer
      theme="dark"
      position="bottom-center"
      bodyClassName="toast-body"
      closeButton={false}
      hideProgressBar={false}
      style={{ width: "36rem" }}
      {...params}
      transition={Slide}
    />
  );
}
