import { useEffect, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import UIKIT from "components/UI";
import { Wrapper } from "./Styles";

type callbackType =
  | ((value: string) => any)
  | ((setTab: (index: number) => void) => void);

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  items: { label: string; href: any }[] | string[] | any[];
  context: any;
  type: "default" | "link";
  callback?: callbackType;
}

export default function Tabs({
  items,
  context,
  type,
  callback,
  ...props
}: Props) {
  const options: { tab: any; setTab: React.SetStateAction<any> } =
    useContext(context);

  const location = useLocation();

  useEffect(() => {
    if (items.length && items[0]?.label) {
      callback && callback(options.setTab);
    }
  }, [location.pathname, options.tab]);

  return (
    <Wrapper className="customScroll" {...props}>
      {items.map((item, index) =>
        type === "link" ? (
          <NavLink to={item?.href} key={index}>
            <UIKIT.Button
              onClick={() => {
                callback && callback(items[index]);
                options.setTab(index);
              }}
              key={index}
              variant="Sort"
              height="3rem"
              className={options.tab === index ? "active" : ""}
            >
              {item?.label}
            </UIKIT.Button>
          </NavLink>
        ) : (
          <UIKIT.Button
            onClick={() => {
              callback && callback(items[index]);
              options.setTab(index);
            }}
            key={index}
            variant="picker"
            height="3rem"
            className={options.tab === index ? "active" : ""}
          >
            {item}
          </UIKIT.Button>
        )
      )}
    </Wrapper>
  );
}
