import MarketplaceWeapon from "./MarketplaceWeapon";
import DashboardServer from "./DashboardServer";
import SkinTransfer from "./SkinTransfer";
import Trade from "./Trade";
import History from "./History";
import TradeSelectorSkins from "./TradeSelectorSkins";
import TradeConfirm from "./TradeConfirm/TradeConfirm";
import SkinChanger from "./SkinChanger";
import Shop from "./Shop";
import { observer } from "mobx-react-lite";
import AdminUserChange from "./AdminUserChange";

export const Modals = {
  MarketplaceWeapon,
  DashboardServer,
  SkinTransfer,
  Trade,
  History,
  TradeSelectorSkins,
  TradeConfirm,
  SkinChanger,
  Shop,
  AdminUserChange,
};
