export enum Banss {
  ban = "ban",
  mute = "chat_mute",
}

export interface IUser {
  group: {
    name: string;
    expired: string;
    vipTest: boolean;
    flags: string;
  };
  // group: string;
  isAdmin?: boolean;
  blocks: [Block];
  user: {
    name: string;
    steamid64: string;
    avatar: string;
    email: string;
    verified: boolean;
  };
  data: {
    name: string;
    verified: boolean;
  };
  balance: {
    real: number;
    virtual: number;
  };
  level: {
    currentLevel: number;
    currentXP: number;
  };
  online: boolean;
  statistics: {
    kills: number;
    deaths: number;
    timePlayed: number;
  };
  servicesInfo: IServiceInfo;
}

interface IServiceInfo {
  number: number;
  flags: string;
  server: string;
  identify: string;
  services: string;
}

export interface Block {
  id: number;
  reason: string;
  expiration: string;
  date: string;
  server: {
    id: number;
    name: string;
    ip: string;
  };
  admin: {
    name: string;
    avatar: string;
    steamid64: string;
  };
  banned: {
    name: string;
    avatar: string;
    steamid64: string;
    ip: string;
    type: Banss;
    state: string;
  };
}
