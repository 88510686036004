import { Button } from "./Styles";
import { IButton } from "interfaces/Button";

export default function Buttons({
  children,
  height,
  variant,
  leftIcon,
  rightIcon,
  color,
  className,
  params,
  ...props
}: IButton) {
  return (
    <Button
      variant={variant}
      height={height}
      color={color}
      className={className}
      fullWidth={props.fullWidth}
      params={params}
      {...props}
    >
      {leftIcon && leftIcon}
      {children}
      {rightIcon && rightIcon}
    </Button>
  );
}
