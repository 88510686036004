import { SelectVariants } from "./../../../../interfaces/Select";
import styled, { css } from "styled-components";

interface Props {
  font?: string;
  variant?: SelectVariants;
}

export const BodyBlock = styled.div<Props>`
  width: fit-content;
  position: absolute;
  top: 4rem;
  left: 0;
  opacity: 0;
  z-index: -2;
  pointer-events: none;
  height: max-content;
  background: ${(props) => props.theme.colors.white.white100};
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  max-height: 50rem;
  gap: 1.5rem;
  padding: 1.5rem;
  ${(props) => props.theme.shadows.defaultShadow};
  transition: 0.3s;
  ${(props) =>
    props.variant === "transparent" &&
    css`
      top: 1rem;
    `}
  &.active {
    opacity: 1;
    top: 7rem;
    left: 1rem;
    z-index: 150;
    pointer-events: all;
    ${(props) =>
      props.variant === "transparent" &&
      css`
        top: 3rem;
        left: 0;
      `}
  }
`;

export const Item = styled.div<Props>`
  cursor: pointer;
  color: ${(props) => props.theme.colors.black.black100};
  ${(props) => props.theme.fonts.GothamRegular16}
  transition: all .15s;
  &:hover {
    ${(props) => props.theme.fonts.GothamBold16}
  }
`;
