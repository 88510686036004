export const gradients = {
  bg: " linear-gradient(287.56deg, #450812 0%, #01193E 95.31%)  ",
  // bgVip: `url("./public/static/images/Background.png"), black`,
  card_fade:
    "linear-gradient(90deg, rgba(27, 17, 18, 0.7) 0%, rgba(27, 17, 18, 0) 51.04%)",
  xp_progress:
    "radial-gradient(80% 100% at 50.00% 0%, rgba(30,145,228,0.49) 0%, rgba(0,0,0,0)  100%)",
  common_grade:
    "radial-gradient(80% 100% at 50.00% 0%, rgba(255,255,255,0.49) 0%, rgba(0,0,0,0) 100%);",
  industrial_grade:
    "radial-gradient(80% 100% at 50.00% 0%, rgba(109,150,214,0.49) 0%, rgba(0,0,0,0) 100%);",
  mil_spec:
    "radial-gradient(80% 100% at 50.00% 0%, rgba(80,106,242,0.49) 0%, rgba(0,0,0,0) 100%);",
  immortal:
    "radial-gradient(80% 100% at 50.00% 0%, rgba(228,174,57,0.49) 0%, rgba(0,0,0,0) 100%);",
  restricted:
    "radial-gradient(80% 100% at 50.00% 0%, rgba(127,80,245,0.49) 0%, rgba(0,0,0,0) 100%);",
  classified:
    "radial-gradient(80% 100% at 50.00% 0%, rgba(194,65,222,0.49) 0%, rgba(0,0,0,0) 100%);",
  covert:
    " radial-gradient(80% 100% at 50.00% 0%, rgba(255,68,68,0.49) 0%, rgba(0,0,0,0) 100%);",
  exceedingly_rare:
    "radial-gradient(80% 100% at 50.00% 0%, rgba(255,199,0,0.49) 0%, rgba(0,0,0,0) 100%);",
  rating_you:
    "linear-gradient(270deg, #192240 89.79%, rgba(30, 228, 62, .75) 100%)",
  rating_first:
    "linear-gradient(270deg, #192240 89.79%, rgba(255, 199, 0, .75) 100%)",
  rating_second:
    "linear-gradient(270deg, #192240 89.79%, rgba(217, 87, 85, .75) 100%)",
  rating_third:
    "linear-gradient(270deg, #192240 89.79%, rgba(194, 65, 222, .75) 100%)",
  shadow: `rgba(255, 255, 255, 0.05)`,
  agent7:
    "radial-gradient(80% 100% at 50.00% 0%, rgba(255,199,0,0.49) 0%, rgba(0,0,0,0) 100%);",
  green:
    "radial-gradient(80% 100% at 50.00% 0%, rgba(68,255,74,0.49) 0%, transparent 100%);",
  player: "linear-gradient(transparent 0%, rgba(72,171,224,0.37) 100%);",
  redUser:
    "radial-gradient(500% 40% at 50.00% 0%, rgba(105, 2, 2) 0%, rgba(0,0,0,1) 100%);",
  purpleUser:
    "radial-gradient(1000% 40% at 100.00% 0%,rgb(120, 0, 116) 0%, rgba(0,0,0,1) 100%);",
};

/*
 * Created with https://www.css-gradient.com
 * Gradient link: https://www.css-gradient.com/?c1=014aff&c2=010101&gt=r&gd=dtt
 */
