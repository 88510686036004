import { endpoints, url } from "API";
import axios from "axios";
import { Type } from "interfaces/Vip";

export class VipServise {
  static async GetShop() {
    try {
      const response = await axios.get(url + endpoints.shop.get, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async GetAdmins() {
    try {
      const response = await axios.get(
        url + endpoints.shop.get + endpoints.shop.admin,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async Сancellation(steamid64: string) {
    try {
      const response = await axios.get(
        url +
          endpoints.shop.get +
          endpoints.shop.cancel +
          "?steamid64=" +
          steamid64,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async BuyGift(
    type: Type,
    privilege_id: number,
    prices_id: number,
    steamid64: string
  ) {
    try {
      const response = await axios.get(
        url +
          endpoints.shop.get +
          (type == "GIFT"
            ? endpoints.shop.gift
            : type == "GRANT"
            ? endpoints.shop.grant
            : endpoints.shop.buy) +
          "?privilege_id=" +
          privilege_id +
          "&prices_id=" +
          prices_id +
          "&steamid64=" +
          steamid64,

        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Authorization"),
          },
        }
      );
      // console.log(response.data);
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }
}
