import Column from 'components/Column'
import { Controllers } from 'components/Controllers'
import { months } from 'consts/months'

import { Control } from 'react-hook-form'
import { getAllDaysInMonth } from 'utils/getAllDaysInMonth'
import { getYears } from 'utils/getYears'
import { IProfileSettings } from '../Settings'
import { Template, Title } from '../Styles'
import { BirthdayWrapper, Wrapper } from './Styles'
interface Props {
	control: Control<IProfileSettings>
}

export default function Profile({ control }: Props) {

	return (
		<Template>
			<Column gap='3rem'>
				<Title>
					Профиль
				</Title>
				<Controllers.Input
					name='name'
					label='Имя'
					control={control}
					placeholder='Имя'
					params={{ width: '100%' }}
				/>
				<Controllers.Input
					name='server_nickname'
					label='Ник на сервере'
					control={control}
					placeholder='Ник'
					params={{ width: '100%' }}
				/>
				<BirthdayWrapper>
					<Wrapper>
						<Controllers.Select
							name='birthday.day'
							control={control}
							items={getAllDaysInMonth()}
							params={{ variant: 'transparent', width: 'fit-content' }}
						/>
					</Wrapper>
					<Wrapper>
						<Controllers.Select
							name='birthday.month'
							control={control}
							items={months}
							params={{ variant: 'transparent', width: 'fit-content' }}
						/>
					</Wrapper>
					<Wrapper hide={true}>
						<Controllers.Select
							name='birthday.year'
							control={control}
							items={getYears()}
							params={{ variant: 'transparent', width: 'fit-content' }}
						/>
					</Wrapper>
				</BirthdayWrapper>
				<Controllers.Input
					name='email'
					label='Email'
					control={control}
					placeholder='Ваш E-mail адрес'
					params={{ width: '100%' }}
				/>
				<Controllers.Input
					name='discord'
					label='Discord'
					control={control}
					placeholder='Ссылка на ваш Discord'
					params={{ width: '100%' }}
				/>
			</Column>
		</Template>
	)
}
