import styled from "styled-components";

interface Props {
  alignItems?: string;
  justifyContent?: string;
  gap?: string;
  fullWidth?: boolean;
  margin?: string;
}

export const Container = styled.div<Props>`
  display: flex;
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  gap: ${(props) => (props.gap ? props.gap : "1rem")};
  width: ${(props) => props.fullWidth && "100%"};
  margin: ${(props) => props.margin};
  @media (max-width: 1250px) {
    width: auto;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    text-align-last: start;
  }
`;
