import styled from "styled-components";

const circleHeight = '2.5rem'

export const ToggleBlock = styled.div`
	cursor: pointer;
	width: 5rem;
	height: ${circleHeight};
	position: relative;
	transition: .3s;
	background: ${props => props.theme.colors.red.accent};
	border-radius: 2.5rem;
	&::before {
		transition: .3s;
		position: absolute;
		top: 0;
		left: 0;
		height: ${circleHeight};
		border-radius: 50%;
		width: 2.5rem;
		content: ' ';
		background: ${props => props.theme.colors.white.white100};
		box-shadow: 0 .3rem .8rem rgba(0, 0, 0, 0.15), 0 .3rem .1rem rgba(0, 0, 0, 0.06);
	}
`

export const Container = styled.div`
	width: fit-content;
	height: fit-content;
	input[type='checkbox'] {
		display: none;
	};
	input:checked + div::before {
		left: calc(100% - ${circleHeight});
	}
	input:checked + div {
		background: ${props => props.theme.colors.green.accent};
	}
`

export const Label = styled.label`
	transition: .3s;
	display: flex;
	align-items: center;
	position: relative;
	gap: 1rem;
	user-select: none;
	${props => props.theme.fonts.GothamRegular20};
	color: ${props => props.theme.colors.white.white100};
	cursor: pointer;
`