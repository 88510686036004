import { url } from "API";
import axios from "axios";

export class ChatService {
  static async getHistory() {
    try {
      const response = await axios.get(url + "/messages", {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async getPrivateChatHistory(steamid64) {
    try {
      const response = await axios(url + "/messages" + steamid64, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }
}
