import Layout from 'components/Layout'
import ProfileLayout from 'components/ProfileLayout'
import { useAppSelector } from 'hooks/useAppSelector'

import AvatarChange from './Avatar'
import Blacklist from './Backlist'
import Ref from './Ref'
import { Grid, Left, Right } from './Styles'
import VK from './VK'
import { useForm } from 'react-hook-form'
import Profile from './Profile'
import AdditionalSettings from './AdditionalSettings'
import SubHeader from 'components/SubHeader'

export interface IProfileSettings {
	name: string;
	server_nickname: string;
	birthday: {
		day: number;
		month: string;
		year: number;
	}
	email: string;
	discord: string;
}

export default function Settings() {

	const { user } = useAppSelector(state => state.user?.user)

	const { control } = useForm<IProfileSettings>({
		defaultValues: {
			name: 'BaDick',
			server_nickname: user?.name || '',
			birthday: {
				day: 11,
				month: 'december',
				year: 2002,
			},
			email: 'mail@gmail.com',
			discord: '#eblan22'
		}
	})

	return (
		<Layout
			leftElement={<div />}
			headerElement={
				<SubHeader>
					Настройки
				</SubHeader>
			}
		>
			<ProfileLayout>
				<Grid>
					<Left>
						<AvatarChange />
						<VK />
						<Ref />
						<Blacklist />
					</Left>
					<Right>
						<Profile control={control} />
						<AdditionalSettings />
					</Right>
				</Grid>
			</ProfileLayout>
		</Layout>
	)
}
