import Layout from "components/Layout";
import Button from "components/UI/Button";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { HeaderWrap, Text, Wrapper, WrapTitle } from "./Styles";

export default function Offer() {
  const navigate = useNavigate();
  const windowInnerWidth = document.documentElement.clientWidth;
  const windowInnerHeight = document.documentElement.clientHeight;
  return (
    <Layout
      leftElement={<div />}
      headerElement={
        <HeaderWrap>
          {/* <UIKIT.ArrowBackMoney /> */}
          {/* <SubHeader>Договор – оферта</SubHeader> */}
          <Button
            variant="picker"
            height="5rem"
            onClick={() => navigate("/rules")}
          >
            Правила
          </Button>
          <Button
            variant="picker"
            height="5rem"
            onClick={() => navigate("/rules/offer")}
          >
            Оферта
          </Button>
          <Button
            variant="picker"
            height="5rem"
            onClick={() => navigate("/rules/PrivatePolicy")}
          >
            Политика конфидициальности
          </Button>
          <Button variant="picker" height="5rem" className="active">
            Контакты
          </Button>
        </HeaderWrap>
      }
    >
      <Helmet>
        <title>FREEZ-BLOOD - Контакты</title>
        <meta name="description" content={`FREEZ-BLOOD - Контакты`} />
      </Helmet>
      {windowInnerWidth < 1250 ? (
        <HeaderWrap>
          {/* <UIKIT.ArrowBackMoney /> */}
          {/* <SubHeader>Договор – оферта</SubHeader> */}
          <Button
            variant="picker"
            height="5rem"
            onClick={() => navigate("/rules")}
          >
            Правила
          </Button>
          <Button variant="picker" height="5rem" className="active">
            Оферта
          </Button>
          <Button
            variant="picker"
            height="5rem"
            onClick={() => navigate("/rules/contact")}
          >
            Контакты
          </Button>
        </HeaderWrap>
      ) : null}
      <Wrapper>
        <WrapTitle>Контакты</WrapTitle>

        <Text>
          Группа ВК:&nbsp;
          <a href="https://vk.com/freezblood" style={{ color: "#00FFFF	" }}>
            https://vk.com/freezblood
          </a>
        </Text>
        <Text>
          Дискорд:&nbsp;
          <a href="https://discord.gg/freez-blood" style={{ color: "#00FFFF	" }}>
            https://discord.gg/freez-blood
          </a>
        </Text>
        <Text>
          Почта для обращений::&nbsp;
          <a href="#" style={{ color: "#00FFFF	" }}>
            support@barsamov.ru
          </a>
        </Text>
        <Text>
          Поддержка на сайте:&nbsp;
          <a
            href="https://freez-blood.com/support"
            style={{ color: "#00FFFF	" }}
          >
            https://freez-blood.com/support
          </a>
        </Text>
      </Wrapper>
    </Layout>
  );
}
