import { endpoints, url } from "API";
import axios from "axios";
import { TicketTypes } from "interfaces/Support";
import { TicketAndBlockStatus } from "models/Ticket";
import { toast } from "react-toastify";

function formatTime(inputTime: string) {
  const months = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  // Преобразуем строку времени в объект Date
  let date = new Date(inputTime);
  date.setHours(date.getHours() - 3);
  const offsetMinutes = new Date().getTimezoneOffset();
  // console.log(inputTime);
  // console.log(date);
  // console.log(offsetMinutes);

  date.setMinutes(date.getMinutes() + offsetMinutes);

  // console.log(date);

  // Получаем день, месяц и год
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  // Получаем часы и минуты
  const hours = date.getHours();
  const minutes = date.getMinutes();
  // Добавляем ведущий ноль к минутам, если число меньше десяти
  const formattedMinutes = String(minutes).padStart(2, "0");
  // Формируем строку с датой и временем
  const formattedTime = `Повторно отправить заявку/обращение можно будет ${day} ${months[monthIndex]} ${year}, ${hours}:${formattedMinutes}`;
  return formattedTime;
}

export class SupportServise {
  static async TiketCreated(
    topic: string,
    text: string,
    type: TicketTypes,
    block_id?: number,
    discord?: string
  ) {
    try {
      const response = await axios.post(
        url + endpoints.support.create,
        {
          text: text,
          topic: topic,
          type: type,
          block_id: block_id,
          discord: discord,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Authorization"),
          },
        }
      );
      return response.data.data.ticket_id;
    } catch (error: unknown) {
      if (
        error &&
        typeof error === "object" &&
        "response" in error &&
        error.response
      ) {
        const errorMessage =
          (error.response as { data?: { message: string } }).data?.message ||
          "Произошла ошибка";
        toast.error(formatTime(errorMessage));
      } else {
        toast.error("Произошла ошибка");
      }
    }
  }
  static async GetBansMy() {
    try {
      const response = await axios.get(url + endpoints.support.getBan, {
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("Authorization"),
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async GetMyTicket() {
    try {
      const response = await axios.get(url + endpoints.support.feedback, {
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("Authorization"),
        },
      });
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
  static async GetTicketAdmin(page: number, perPage: number) {
    try {
      const response = await axios.get(url + endpoints.support.feedTik + "s", {
        params: {
          page: page,
          perPage: perPage,
        },
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("Authorization"),
        },
      });
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async GetTicketAdminClosed(page: number, perPage: number) {
    try {
      const response = await axios.get(url + endpoints.support.fedTickClose, {
        params: {
          page: page,
          perPage: perPage,
        },
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("Authorization"),
        },
      });
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async GetBlockAdminClosed(
    page: number,
    perPage: number,
    serverId?: number
  ) {
    try {
      const response = await axios.get(url + endpoints.support.fedBlockClose, {
        params: {
          page: page,
          perPage: perPage,
          server_id: serverId,
        },
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("Authorization"),
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async GetBlockAdmin(page: number, perPage: number, serverId?: number) {
    try {
      const response = await axios.get(url + endpoints.support.feedTikBlock, {
        params: {
          page: page,
          perPage: perPage,
          server_id: serverId,
        },
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("Authorization"),
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
  static async GetMyTicketOne(id: string) {
    try {
      const response = await axios.get(
        url + endpoints.support.feedTik + "/" + id,
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Authorization"),
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
  static async GetHistoryMessage(id: string) {
    try {
      const response = await axios.get(
        url + endpoints.support.feedTik + "/msgHistory/" + id,
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Authorization"),
          },
        }
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
  static async CloseTik(ticket_id: number, status: TicketAndBlockStatus) {
    try {
      const response = await axios.post(
        url + endpoints.support.feedTik + endpoints.support.closeTik,
        {
          ticket_id: ticket_id,
          status: status,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Authorization"),
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
}
