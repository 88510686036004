import styled from "styled-components";

export const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 3rem;
	margin-bottom: 4rem;
`

export const Template = styled.div`
	width: 100%;
	border-radius: 2.5rem;
	background: transparent;
	${props => props.theme.mixins.defaultShadow};
	padding: 3rem;
	height: fit-content;
`

export const Title = styled.h1`
	${props => props.theme.fonts.GothamRegular24};
	color: ${props => props.theme.colors.white.white100};
`

export const Left = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 3rem;
`

export const Right = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 3rem;
`