export default function VK() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.6875 1.6875C0 3.37406 0 6.08906 0 11.52V11.5209V12.4809C0 17.9119 0 20.625 1.6875 22.3125C3.37406 24 6.08906 24 11.52 24H12.48C17.9109 24 20.625 24 22.3125 22.3125C24 20.6259 24 17.9109 24 12.48V11.52C24 6.08906 24 3.375 22.3125 1.6875C20.6259 0 17.9109 0 12.48 0H11.52C6.08906 0 3.375 0 1.6875 1.6875ZM4.04688 7.30029C4.17719 13.5403 7.29719 17.2903 12.7666 17.2903H12.7675H13.0769V13.7203C15.0869 13.92 16.6075 15.39 17.2169 17.2903H20.0566C19.2766 14.4497 17.2272 12.8803 15.9466 12.2803C17.2272 11.5397 19.0272 9.73967 19.4566 7.30029H16.8766C16.3169 9.28029 14.6566 11.0803 13.0769 11.25V7.30029H10.4969V14.22C8.89656 13.8197 6.87719 11.88 6.78719 7.30029H4.04688Z"
        fill="white"
        // fill-opacity="0.5"
      />
    </svg>
  );
}
