import { endpoints, url } from "API";
import axios from "axios";

export class UserService {
  static getAuthToken() {
    throw new Error("Method not implemented.");
  }
  static convertSkinsToBackEnd(skins) {
    return skins
      .filter((skin) => skin.isSelected)
      .map((skin) => {
        return {
          steamid: skin.steamid,
          category: "EXITEMS_WS",
          item: skin.skinInfo.item,
          group: skin.skinInfo.group,
          amount: skin.selectedCount,
        };
      });
  }

  static async getProfile() {
    // const fetchToken = async () => {
    //   const authToken = this.gitTokenAuth();

    //   localStorage.setItem("authToken", authToken);
    // };
    // fetchToken();
    try {
      const response = await axios.get(url + endpoints.user.profile, {
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async ExitProfile() {
    try {
      const response = await axios.get(url + endpoints.user.exit, {
        withCredentials: true,
      });
      // console.log(url + endpoints.user.exit);
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }

  static async StatistickGet(steamid) {
    try {
      const response = await axios.get(
        url + endpoints.user.profile + "/" + steamid + endpoints.user.statsss,
        {
          withCredentials: true,
        }
      );
      // console.log(url + endpoints.user.exit);
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }

  static async getAnotherProfile(steamid64) {
    try {
      const response = await axios(
        url + endpoints.user.profile + `/${steamid64}`,
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }
  static async getFriends(steamid64) {
    try {
      const response = await axios.get(
        url + `/profile/${steamid64}` + endpoints.user.friends,
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async addFriend(steamid64) {
    try {
      const response = await axios.get(
        url + `/profile/createFriendRequest/${steamid64}`,
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async getFriendRequests(steamid64) {
    try {
      const response = await axios.get(
        url + `/profile/friendsReceive/${steamid64}`,
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async acceptFriend(steamid64, status) {
    try {
      const response = await axios.post(
        url + `/profile/acceptFriend/${steamid64}`,
        { status },
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async cancelRequest(mysteamid, steamid64, status) {
    try {
      const response = await axios.post(
        url + `/profile/cancelRequest/${mysteamid}`,
        { steamid64 },
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async getFriendRequests(steamid64) {
    try {
      const response = await axios.get(
        url + `/profile/friendsReceive/${steamid64}`,
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async getFriendSent(steamid64) {
    try {
      const response = await axios.get(
        url + `/profile/friendsSent/${steamid64}`,
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async getInventory(
    steamid64,
    page,
    classId,
    weapon,
    perPage,
    raritySort
  ) {
    try {
      const response = await axios.post(
        url + `/profile/${steamid64}` + endpoints.user.inventory,
        {
          page: page,
          classId: classId,
          weapon: weapon,
          perPage: perPage,
          raritySort: raritySort,
        },
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async getServerInventory(steamid64) {
    try {
      const response = await axios.get(
        url + `/profile/${steamid64}` + endpoints.user.serverInventory,
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async sendInventoryToServer(steamid64, skins) {
    let finalSkins = this.convertSkinsToBackEnd(skins);

    try {
      const response = await axios.post(
        url + `/profile/${steamid64}` + endpoints.user.inventoryToServer,
        finalSkins,
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async sendInventoryFromServer(steamid64, skins) {
    let finalSkins = this.convertSkinsToBackEnd(skins);

    try {
      const response = await axios.post(
        url + `/profile/${steamid64}` + endpoints.user.inventoryFromServer,
        finalSkins,
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async gitTokenAuth() {
    try {
      const response = await axios.get(url + endpoints + "/token", {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }
}
