import styled from "styled-components";

export const GridLayout = styled.div<{ count: number }>`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(30rem, ${(props) => (props.count > 2 ? "1fr" : "51.5rem")})
  );
  grid-template-rows: repeat(auto-fill, minmax(10rem, 14rem));
  align-items: stretch;
  transition: 0.3s all;
  gap: 1rem;
  margin-left: 1rem;
`;

export const RowLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Head = styled.div`
  width: 100%;
  height: 5rem;
  background: ${(props) => props.theme.colors.white.white10};
  padding: 1.6rem 4.8rem 1.4rem 3rem;
  border-radius: 1.5rem;
  display: grid;
  grid-template-columns: 0.84fr 0.3fr 0.3fr 0.4fr 0.1fr;
`;

export const HeadItem = styled.span`
  ${(props) => props.theme.fonts.GothamMedium20};
  color: ${(props) => props.theme.colors.white.white100};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-height: 87rem;
  height: 100%;
`;
