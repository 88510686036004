export default function Telegram() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2595_15602)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.51562 3.51469C5.76375 1.26506 8.81812 0 12 0C15.1819 0 18.2362 1.26506 20.4844 3.51469C22.7344 5.76431 24 8.81869 24 12C24 15.1813 22.7344 18.2357 20.4844 20.4853C18.2362 22.7349 15.1819 24 12 24C8.81812 24 5.76375 22.7349 3.51562 20.4853C1.26562 18.2357 0 15.1813 0 12C0.000623381 8.81734 1.26518 5.76521 3.51562 3.51469ZM12.4323 8.85904C11.266 9.34448 8.93352 10.3493 5.43477 11.8733C4.86665 12.0992 4.56852 12.3203 4.54227 12.5363C4.49611 12.9012 4.95308 13.0451 5.57402 13.2406C5.65919 13.2675 5.74744 13.2953 5.8379 13.3247C6.45102 13.5239 7.27602 13.7569 7.70352 13.7661C8.09352 13.7745 8.52665 13.6142 9.00477 13.2854C12.2748 11.079 13.9604 9.96379 14.0673 9.93979C14.1423 9.92292 14.2454 9.90154 14.3148 9.96398C14.386 10.0262 14.3785 10.1444 14.371 10.176C14.3268 10.3688 12.5385 12.0314 11.6079 12.8966C11.3153 13.1686 11.1076 13.3618 11.0654 13.4057C10.9712 13.5036 10.8752 13.5961 10.7829 13.6851C10.2139 14.2336 9.787 14.6451 10.8066 15.3169C11.3011 15.6426 11.6957 15.9114 12.0901 16.18C12.5139 16.4687 12.9374 16.7571 13.4841 17.1158C13.6248 17.2079 13.759 17.3036 13.8898 17.3969C14.3868 17.7511 14.8332 18.0694 15.3854 18.0186C15.706 17.9892 16.0379 17.6877 16.2048 16.7884C16.6023 14.6631 17.3841 10.0585 17.5641 8.16098C17.581 7.99485 17.5604 7.78204 17.5454 7.68867C17.5285 7.5952 17.4948 7.46207 17.3748 7.36364C17.2304 7.24692 17.0091 7.22235 16.9098 7.22395C16.4579 7.2321 15.766 7.47285 12.4323 8.85904Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2595_15602">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
