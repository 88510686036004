import styled from "styled-components";

interface Props {
  params?: {
    width: string;
  };
}

export const Wrapper = styled.div<Props>`
  flex-wrap: wrap;
  gap: 1rem 0;
  height: fit-content;
  max-width: ${(props) =>
    props.params?.width ? props.params.width : "100rem"};
  width: 100%;
  height: fit-content;
  padding: 0.5rem 2rem;
  ${(props) => props.theme.mixins.dfspaceBetween};
  border-radius: 1.5rem;
  background: ${(props) => props.theme.colors.white.white10};
  @media (max-width: 1250px) {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    width: auto;
    padding: 0.5rem 2rem 1.5rem;
    margin-left: 1rem;
  }
`;
