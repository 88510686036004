import { Icons } from "assets/icons";
import Modal from "components/Modal/Modal";
import { ModalParams } from "interfaces/Modal";

import { Container, Head, UserContainer } from "./Styles";
import Avatar from "components/Avatar/Avatar";

interface Props {
  children: React.ReactElement | React.ReactElement[] | string;
  headContent: any;
  isActive: boolean;
  setActive: React.SetStateAction<any>;
  params?: ModalParams;
  tradeInfo?: {
    name: string;
    avatar: string;
  };
  display: string;
}

export default function ModalWrapper({
  children,
  headContent,
  isActive,
  setActive,
  params,
  tradeInfo,
  display,
}: Props) {
  return (
    <Modal isActive={isActive} setActive={setActive}>
      <Container params={params}>
        <Head display={display === "none" ? "none" : "flex"}>
          <i />
          {headContent}
          <Icons.Close onClick={() => setActive(false)} />
        </Head>
        {tradeInfo?.name && (
          <UserContainer>
            <Avatar isBorder={false} size="small" src={tradeInfo.avatar} />{" "}
            {tradeInfo.name}
          </UserContainer>
        )}
        <div style={{ display: "flex", flexDirection: "column", gap: "3rem" }}>
          {children}
        </div>
      </Container>
    </Modal>
  );
}
