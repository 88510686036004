import { IInput } from "interfaces/Input";

import {
  InputBlock,
  Label,
  Text,
  ComareWrapper,
  ComareInput,
  ComareMax,
} from "./Styles";

export default function Input({
  mask,
  maskChar,
  label,
  params,
  placeholder,
  compare,
  compareText,
  compareSet,
  compareGet,
  pad,
  ...props
}: IInput) {
  return (
    <Label width={params?.width} color={params?.labelColor}>
      {label && <Text params={params}>{label}</Text>}
      {compare ? (
        <ComareWrapper>
          <ComareInput
            onChange={(e) => compareSet(e.target.value)}
            value={compareGet}
          />
          <ComareMax>|</ComareMax>
          <ComareMax>{compareText}</ComareMax>
        </ComareWrapper>
      ) : (
        <InputBlock
          pad={pad}
          mask={mask}
          maskChar={maskChar}
          placeholder={placeholder}
          params={params}
          {...props}
        />
      )}
    </Label>
  );
}
