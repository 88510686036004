import Column from 'components/Column'
import Row from 'components/Row'
import Button from 'components/UI/Button'

import { Template, Title } from '../Styles'
import { Block, Link } from './Styles'

export default function Ref() {
	return (
		<Template>
			<Column gap='3rem'>
				<Title>
					Реферальная программа
				</Title>
				<Block>
					Пользователи, зарегистрированные на сайте по вашей ссылке, будут являться Вашими рефералами. При пополнении своего баланса рефералом, Вы будете получать на Ваш баланс 15% от суммы его пополнения.
				</Block>
				<Link>
					Ваша уникальная ссылка: <a href="https://freez-blood.ru/?ref=22175">https://freez-blood.ru/?ref=22175</a>
				</Link>
				<Row gap='3rem' fullWidth>
					<Button
						height='5rem'
						variant='default'
						color='blue'
						fullWidth
					>
						Мои рефералы
					</Button>
					<Button
						height='5rem'
						variant='default'
						color='blue'
						fullWidth
					>
						Моя прибыль
					</Button>
				</Row>
			</Column>
		</Template>
	)
}
