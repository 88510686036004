import { TinyIcons } from "assets/tiny-icons";
import { SelectVariants } from "interfaces/Select";

import { HeadBlock, Text } from "./Styles";

interface Props {
  position?: "left" | "center" | "right";
  label?: string;
  setShow: React.SetStateAction<any>;
  show: boolean;
  defaultValue?: string;
  height?: string;
  variant?: SelectVariants;
  font?: string;
}

export default function Head({
  setShow,
  show,
  defaultValue,
  label,
  height,
  position,
  variant,
  font,
}: Props) {
  return (
    <HeadBlock
      onClick={() => setShow(!show)}
      className={show ? "active" : ""}
      height={height}
      position={position}
      variant={variant}
    >
      {defaultValue ? (
        <Text font={font}>{defaultValue}</Text>
      ) : (
        <Text font={font}>{label ? label : "Не выбрано"}</Text>
      )}
      <TinyIcons.Arrow />
    </HeadBlock>
  );
}
