import { asideItems } from "consts/aside";
import Image from "../Image";
import { Item, Logo, StyledAside, Wrapper } from "./Styles";

export default function Aside() {
  return (
    <StyledAside className="customScroll">
      <Logo>
        <Image src="/static/images/logo.svg" alt="Logo" />
      </Logo>
      <Wrapper>
        {asideItems.map((item, index) => (
          <Item to={item.link} key={index}>
            {item.icon}
            {item.label}
          </Item>
        ))}
      </Wrapper>
    </StyledAside>
  );
}
