import styled from "styled-components";

export const Subtitle = styled.h5`
	${props => props.theme.fonts.GothamRegular20};
	color: ${props => props.theme.colors.white.white100};
`

export const Toggles = styled.div`
	display: flex;
	flex-direction: column;
	gap: 3rem;
	align-self: flex-start;
`