import Column from 'components/Column'
import Button from 'components/UI/Button'

import { Template, Title } from '../Styles'

export default function VK() {
	return (
		<Template>
			<Column gap='3rem'>
				<Title>
					Аккаунт ВКонтакте
				</Title>
				<Button
					height='5rem'
					variant='default'
					color='blue'
					fullWidth
				>
					Привязать профиль ВКонтакте
				</Button>
			</Column>
		</Template>
	)
}
