import { endpoints, url } from "API";
import { MarketplaceService } from "API/MarketplaceService";
import { socketListener } from "API/SocketProvider";
import { appSocket } from "App";
import { Icons } from "assets/icons";
import { TinyIcons } from "assets/tiny-icons";
import Avatar from "components/Avatar";
import WeaponCard from "components/Cards/WeaponCard";
import Column from "components/Column";
import Layout from "components/Layout";
import LayoutIcon from "components/LayoutIcon";
import { Modals } from "components/Modal";
import Row from "components/Row";
import Filters from "components/Templates/Filters";
import UIKIT from "components/UI";
import Button from "components/UI/Button";
import { MarketplaceContext } from "context/marketplace";
import { useActions } from "hooks/useActions";
import { useAppSelector } from "hooks/useAppSelector";
import { Banss } from "models/User";
import { IWeaponCard } from "models/WeaponCard";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { tradeData } from "utils/convertData";
import rarityConvector from "utils/rarityConvector";
import { UpdateBalance, UpItem } from "./MarketplaceState";
import {
  Body,
  BodyElement,
  BodyItem,
  BodyItemItem,
  // Server,
  Duration,
  Head,
  HeadItem,
  Reason,
  Table,
  Weapons,
} from "./Styles";

interface test {
  data: IWeaponCard[];
}

enum ModeList {
  buy = 0,
  sell = 1,
  change = 2,
  history = 3,
}

enum HistoryType {
  sell = 0,
  buy = 1,
}
interface marketplaceHistory {
  success: boolean;
  data: HistoryItem[];
}

interface HistoryItem {
  steamid64: string;
  avatar: string;
  date: string;
  name: string;
  price: number;
  type: HistoryType;
  skinInfo: {
    name: string;
    rarity: number;
  };
}

export default function Marketplace() {
  const { marketInventory } = useAppSelector((state) => state.user);
  const { totalInv } = useAppSelector((state) => state.user);
  const { selling } = useAppSelector((state) => state.marketplace);
  const { total } = useAppSelector((state) => state.marketplace);
  const { isAuth } = useAppSelector((state) => state.user);
  ////

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [classId1, setClassId] = useState<number>(1);
  const [weapon, setWeapon] = useState<number>();
  const [raritySort, setRaritySort] = useState<string | undefined>(undefined);
  const [priceSort, setPriceSort] = useState<string | undefined>(undefined);

  const [useSortPrice, setUseSortPrice] = useState<boolean | undefined>(
    undefined
  );
  const [useSortRarity, setUseSortRarity] = useState<boolean | undefined>(
    undefined
  );
  const [useClass, setUseClass] = useState<boolean>();
  // console.log(stateUp);
  const { user } = useAppSelector((state) => state.user);
  const [modal, setModal] = useState(false);
  const [mode, setMode] = useState(0);
  const perPage: number = PageWidth();

  // useEffect(() => {
  //   console.log("lox", _);
  // }, []);

  // useEffect(() => {}, [page, total, totalInv, weapon, raritySort, classId1]);
  useEffect(() => {
    setPage(1);
  }, [mode, weapon, classId1, raritySort, priceSort]);
  // console.log("perPage", perPage);
  useMemo(() => {
    setClassId(1);
    setWeapon(undefined);
    setRaritySort(undefined);
    setPriceSort(undefined);
    setUseClass(undefined);
  }, [mode]);

  useEffect(() => {
    if (mode == ModeList.buy) {
      setTotalPage(total);
    } else if (mode == ModeList.sell) {
      setTotalPage(totalInv || 0);
    }
  }, [mode, total, totalInv]);

  const [currentWeapon, setCurrentWeapon] = useState<IWeaponCard>({
    steamid: "",
    category: "",
    item: 1,
    group: 1,
    price: "1",
    src: "",
    cl: false,
    skin: "",
    weapon: "",
    rarity: "mil_spec",
    paint_kit_id: null,
  });

  function PageWidth() {
    const screenWidth = window.screen.width;
    // console.log("screenWidth", screenWidth);
    if (screenWidth <= 4604 && screenWidth >= 2536) {
      return 65;
    } else if (screenWidth <= 2536 && screenWidth >= 2364) {
      return 60;
    } else if (screenWidth <= 2364 && screenWidth >= 2192) {
      return 55;
    } else if (screenWidth <= 2192 && screenWidth >= 2020) {
      return 50;
    } else if (screenWidth <= 2020 && screenWidth >= 1841) {
      return 45;
    } else if (screenWidth <= 1841 && screenWidth >= 1676) {
      return 40;
    } else if (screenWidth <= 1676 && screenWidth >= 1504) {
      return 35;
    } else if (screenWidth <= 1504 && screenWidth >= 1332) {
      return 30;
    } else if (screenWidth <= 1332 && screenWidth >= 1160) {
      return 23;
    } else if (screenWidth <= 1160 && screenWidth >= 100) {
      return 23;
    } else {
      return 30;
    }
  }

  const { fetchMarketplace, fetchSalesItems, fetchMarketInventory } =
    useActions();
  const { data }: test = useAppSelector((state) => state.marketplace);
  const [history, setHistory] = useState<marketplaceHistory>({
    success: false,
    data: [],
  });

  // useEffect(() => {
  //   setTotal
  // },[mode])

  //------------------------------------------------------------------------------------------------------------------------
  const [newArrayData, setNewArrayData] = useState<IWeaponCard[]>([]);
  const [newArraySell, setNewArraySell] = useState<IWeaponCard[]>([]);
  const [newArrayMarket, setNewArrayMarket] = useState<IWeaponCard[]>([]);
  const [stateUpInv, setStateUpInv] = useState<UpItem>();
  // const [timer, setTimer] = useState(false);
  const [stateUp, setStateUp] = useState<UpdateBalance>();
  // const [idNumberMarket, setIdNumberMarket] = useState<number[]>([]);

  useEffect(() => {
    appSocket.on(socketListener.marketUpdate, (action: UpdateBalance) => {
      // console.log("action", action);
      setStateUp(action);
    });
    appSocket.on(socketListener.updateInventory, (action: UpItem) => {
      setStateUpInv(action);
    });
  }, []);

  useMemo(() => {
    if (!stateUp) {
      setNewArrayData(data);
      setNewArraySell(selling);
      setNewArrayMarket(marketInventory);
    }
  }, [data, selling, marketInventory]);

  useMemo(() => {
    setNewArrayMarket(marketInventory);
  }, [marketInventory]);
  useMemo(() => {
    setNewArrayData(data);
  }, [data]);

  useMemo(() => {
    if (stateUp?.action == "DELETE" && mode === ModeList.buy) {
      DeleteItemMarket();
    }
    if (stateUp?.action == "UPDATE" && mode === ModeList.buy) {
      UpdateItemMarket();
    }
    if (
      stateUp?.action == "ADD" &&
      page === Math.round(total / perPage) + 1 &&
      classId1 == 1
    ) {
      // console.log(1);
      AddItemMarket();
    }
    if (stateUp?.action == "ADD" && mode === ModeList.sell) {
      DeleteAddItemInventory();
    }
    if (stateUp?.action == "DELETE" && mode === ModeList.change) {
      DeleteItemChange();
    }
    if (stateUp?.action == "UPDATE" && mode === ModeList.change) {
      UpdateItemChange();
    }
    if (
      stateUp?.action == "ADD" &&
      mode === ModeList.sell &&
      stateUp.item.item_id === stateUpInv?.item.id &&
      stateUpInv?.item.price !== null &&
      stateUpInv
    ) {
      setNewArraySell([...newArraySell, stateUpInv.item]);
    }
    if (
      stateUp?.action == "DELETE" &&
      mode == ModeList.change &&
      stateUp.item.item_id === stateUpInv?.item.id &&
      stateUpInv
    ) {
      setNewArrayMarket([...newArrayMarket, stateUpInv.item]);
    }
    if (
      stateUp?.action == "DELETE" &&
      mode == ModeList.buy &&
      stateUp.item.item_id == stateUpInv?.item.id &&
      stateUpInv
    ) {
      setNewArrayMarket([...newArrayMarket, stateUpInv.item]);
    }
  }, [stateUp, stateUpInv]);

  function UpdateItemChange() {
    let j: IWeaponCard[] = [];
    // console.log(item)

    // console.log(user.user.id)
    newArraySell.forEach((item) => {
      if (item.id == stateUp?.item.item_id) {
        const obj: IWeaponCard = {
          id: item.id,
          steamid: item.steamid64,
          nickname: item.nickname,
          avatar: item.avatar,
          src: item.src,
          price: stateUp?.item.price,
          skin: item.skin,
          weapon: item.weapon,
          rarity: item.rarity,
        };
        j.push(obj);
      } else {
        j.push(item);
      }
    });
    setNewArraySell(j);
  }

  function DeleteItemChange() {
    let j: IWeaponCard[] = [];

    newArraySell.forEach((item) => {
      if (item.id !== stateUp?.item.item_id) {
        j.push(item);
      }
    });
    setNewArraySell(j);
  }

  function DeleteAddItemInventory() {
    let j: IWeaponCard[] = [];

    // console.log("user.user.steamid64", user.user.steamid64);
    // console.log("stateUp?.item.steamid64", stateUp?.item.steamid64);
    // console.log("user.user.steamid64", user.user.steamid64);
    // console.log("stateUp?.item.item_id", stateUp?.item.item_id);

    newArrayMarket.forEach((item) => {
      // console.log("item.id", item.id);

      if (item.id !== stateUp?.item.item_id) {
        j.push(item);
      }
    });
    setNewArrayMarket(j);
  }
  function AddItemMarket() {
    let j: IWeaponCard[] = [];
    newArrayData.forEach((item) => {
      j.push(item);
    });
    if (
      stateUp?.item.src !== undefined &&
      stateUp?.item.steamid !== user.user.steamid64
    ) {
      j.push(stateUp?.item);
    }
    setNewArrayData(j);
  }

  function UpdateItemMarket() {
    let j: IWeaponCard[] = [];

    newArrayData.forEach((item) => {
      if (item.id == stateUp?.item.id) {
        const obj: IWeaponCard = {
          id: item.id,
          steamid: item.steamid64,
          nickname: item.nickname,
          avatar: item.avatar,
          src: item.src,
          price: stateUp?.item.price,
          skin: item.skin,
          weapon: item.weapon,
          rarity: item.rarity,
        };
        j.push(obj);
      } else {
        j.push(item);
      }
    });
    setNewArrayData(j);
  }

  function DeleteItemMarket() {
    let j: IWeaponCard[] = [];

    newArrayData.forEach((item) => {
      if (item.id !== stateUp?.item.id) {
        j.push(item);
      }
    });
    // console.log(j);
    setNewArrayData(j);
  }

  const windowInnerWidth = document.documentElement.clientWidth;

  useEffect(() => {
    setFoo("");
  }, [mode]);

  interface filters {
    id: number;
    single: boolean;
    name: string;
    items: filterItem[];
  }
  interface filterItem {
    id: number;
    item_id: number;
    class_ru: string;
  }

  const [filters, setFilters] = useState<Array<filters>>([]);
  const [_, setFoo] = useState<string | undefined>();
  // console.log(_);
  const [_1, setFoo1] = useState("");
  const [filItem, setFilitem] = useState([""]);

  //-------------------------------------------------------------

  //------------------------------------------------------
  useEffect(() => {
    const fetchFilters = async () => {
      const filters = await MarketplaceService.getFilters();
      setFilters(filters.classes);
    };
    // PageWidth();
    fetchFilters();
    fetchMarketplace({
      page: page,
      perPage: perPage,
      classId: classId1,
      weapon: weapon,
      priceSort: priceSort,
      raritySort: raritySort,
    });
    fetchSalesItems();
    fetchMarketInventory({
      page: page,
      perPage: perPage,
      classId: classId1,
      weapon: weapon,
      raritySort: raritySort,
    });
  }, [1]);

  useMemo(() => {
    switch (mode) {
      case 0:
        fetchMarketplace({
          page: page,
          perPage: perPage,
          classId: classId1,
          weapon: weapon,
          priceSort: priceSort,
          raritySort: raritySort,
        });
        break;
      case 1:
        fetchMarketInventory({
          page: page,
          perPage: perPage,
          classId: classId1,
          weapon: weapon,
          raritySort: raritySort,
        });

        break;
      case 2:
        fetchSalesItems();
        break;
    }

    // console.log(data);
  }, [classId1, weapon, priceSort, raritySort, weapon, page]);

  // console.log(
  //   "ban",
  //   user?.blocks?.find((item) => item.banned.type == Banss.ban)
  // );

  function priceUpdate() {
    // fetchMarketplace({
    //   page: page,
    //   perPage: perPage,
    //   classId: classId1,
    //   weapon: weapon,
    //   priceSort: priceSort,
    //   raritySort: raritySort,
    // });
    // fetchSalesItems();
    fetchMarketInventory({
      page: page,
      perPage: perPage,
      classId: classId1,
      weapon: weapon,
      raritySort: raritySort,
    });
  }

  useEffect(() => {
    setUseClass(true);
    let a;
    setFoo1("");
    filters.map((item) => {
      if (_ == item.name) {
        setClassId(item.id);
        setWeapon(undefined);
        a = item.items.map((item) => {
          return item.class_ru;
        });
      }
      return;
    });

    if (a !== null && a !== undefined) {
      setFilitem(a);
    }
  }, [_]);

  useMemo(() => {
    MarketplaceService.getMarketplaceHistory().then((data) => {
      setHistory(data);
    });
  }, [mode == ModeList.history]);

  function loginHandler() {
    window.location.href = url + endpoints.auth.login;
  }

  useMemo(() => {
    setUseClass(false);
    filters.map((item) => {
      item.items.map((i) => {
        if (i.class_ru == _1) {
          setWeapon(i.id);
        }
      });
    });
  }, [_1]);

  const isMySkin = (id: any) => {
    return id === user.user?.steamid64;
  };

  function sortPrice() {
    setUseSortPrice(true);
    setUseSortRarity(false);
    setRaritySort(undefined);
    if (priceSort == undefined) {
      setPriceSort("DESC");
    }
    if (priceSort == "ASC") {
      setPriceSort("DESC");
    }
    if (priceSort == "DESC") {
      setPriceSort("ASC");
    }
  }

  function sortRarity() {
    setPriceSort(undefined);
    setUseSortRarity(true);
    setUseSortPrice(false);
    if (raritySort == undefined) {
      setRaritySort("DESC");
    }
    if (raritySort == "ASC") {
      setRaritySort("DESC");
    }
    if (raritySort == "DESC") {
      setRaritySort("ASC");
    }
  }

  const getItems = () => {
    // console.log("fsdfsdfdsfsdfsdfsdfdsf");
    // console.log(MarketPlaceState.UpdateMarket);
    if (mode == ModeList.buy) {
      if (!isAuth && localStorage.Authorization == undefined) {
        return (
          <UIKIT.Button
            color="blue"
            variant="default"
            height="50px"
            params={{ width: "100%" }}
            onClick={loginHandler}
          >
            Авторизуйтесь
          </UIKIT.Button>
        );
      }
      return (
        newArrayData &&
        newArrayData.map((item, index) => (
          <WeaponCard
            key={index}
            cl={false}
            src={item.src}
            skin={item.skin}
            weapon={item.weapon}
            price={item.price}
            nickname={item.nickname}
            avatar={item.avatar}
            paint_kit_id={null}
            rarity={rarityConvector(+item.rarity)}
            // rarity="exceedingly_rare"
            owner={item.owner}
            onClick={() => {
              if (isMySkin(item?.steamid64)) return;
              setCurrentWeapon(newArrayData[index]);
              setModal(true);
            }}
            disableAvatar
          />
        ))
      );
    }

    if (mode == ModeList.sell) {
      return newArrayMarket.map((item, index) => {
        return (
          <WeaponCard
            key={index}
            src={item.src}
            paint_kit_id={null}
            cl={false}
            skin={item.skin}
            weapon={item.weapon}
            price={item.price}
            rarity={rarityConvector(+item.rarity)}
            owner={item.owner}
            // counter={item.skinInfo.amount > 1 ? `${item.skinInfo.amount}` : ""}
            onClick={() => {
              setCurrentWeapon(newArrayMarket[index]);
              setModal(true);
            }}
            disablePrice
            disableAvatar
          />
        );
      });
    }

    if (mode == ModeList.change) {
      return newArraySell.map((item, index) => {
        return (
          <WeaponCard
            key={index}
            src={item.src}
            paint_kit_id={null}
            skin={item.skin}
            weapon={item.weapon}
            price={item.price}
            cl={false}
            rarity={rarityConvector(+item.rarity)}
            owner={item.owner}
            onClick={() => {
              setCurrentWeapon(newArraySell[index]);
              setModal(true);
            }}
            disableAvatar
          />
        );
      });
    }

    if (mode == ModeList.history) {
      return (
        <Table>
          <Head>
            <HeadItem>№</HeadItem>
            <HeadItem>Тип</HeadItem>
            <HeadItem>Предмет</HeadItem>
            <HeadItem>Цена (Кредитов)</HeadItem>
            <HeadItem>Дата</HeadItem>
            <HeadItem>Сделка с игроком</HeadItem>
          </Head>
          <Body>
            {history.data &&
              history.data.map((item, index) => {
                const type =
                  item.type == HistoryType.sell ? "Продажа" : "Покупка";
                const typeColor =
                  item.type == HistoryType.sell ? "#E32020" : "#1EE43E";

                return (
                  <BodyElement key={index}>
                    <BodyItem>{index + 1}</BodyItem>
                    <BodyItem color={typeColor}>{type}</BodyItem>
                    <BodyItemItem
                      rarity={rarityConvector(item.skinInfo.rarity)}
                    >
                      {item.skinInfo.name}
                    </BodyItemItem>

                    <BodyItem>{item.price}</BodyItem>
                    <Duration>{tradeData(item.date)}</Duration>
                    <Reason to={`/profile/${item.steamid64}`}>
                      <Avatar
                        isBorder={false}
                        src={item.avatar}
                        size="super-small"
                      />
                      {item.name}
                    </Reason>
                  </BodyElement>
                );
              })}
          </Body>
        </Table>
      );
    }
  };

  return (
    <MarketplaceContext.Provider value={currentWeapon}>
      <Helmet>
        <title>FREEZ-BLOOD - Торговая площадка</title>
        <meta name="description" content="FREEZ-BLOOD - Торговая площадка" />
      </Helmet>
      {mode == ModeList.buy || mode == ModeList.sell ? (
        <Layout
          total={totalPage}
          activePages={true}
          setPage={setPage}
          page={page}
          perPage={perPage}
          headerElement={
            <Filters params={{ width: "unset" }}>
              <UIKIT.DropDown
                onChange={setFoo}
                setUse={useClass}
                // useSort={useSortPrice}
                items={filters.map((item) => {
                  // setClassId(item.id);
                  return item.name;
                })}
                headParams={{
                  title: "Класс:",
                  icon: <TinyIcons.Arrow />,
                }}
              />
              {_ !== undefined && _ !== "Все" && _ !== "Агенты" && _ !== "" ? (
                <UIKIT.DropDown
                  onChange={setFoo1}
                  items={filItem}
                  setUse={useClass}
                  headParams={{
                    title: "Тип:",
                    icon: <TinyIcons.Arrow />,
                  }}
                />
              ) : null}
              {mode == ModeList.buy ? (
                <Button
                  height="0"
                  variant="Sort"
                  params={{ padding: "0" }}
                  color="Gray"
                  onClick={() => sortPrice()}
                >
                  <UIKIT.DropDown
                    useSort={useSortPrice}
                    onChange={null}
                    items={["pric"]}
                    // style={{ marginBottom: "20px" }}
                    headParams={{
                      title: "Цена:",
                      icon: <TinyIcons.Arrow />,
                    }}
                  />
                </Button>
              ) : null}

              <Row
                gap="3rem"
                fullWidth
                justifyContent="space-between"
                style={{
                  minWidth: "38.1rem",
                  maxWidth: "43.2rem",
                }}
              >
                <Button
                  height="0"
                  variant="Sort"
                  params={{ padding: "0" }}
                  color="Gray"
                  onClick={() => sortRarity()}
                >
                  <UIKIT.DropDown
                    onChange={null}
                    useSort={useSortRarity}
                    items={["rar"]}
                    headParams={{
                      title: "Редкость:",
                      icon: <TinyIcons.Arrow />,
                    }}
                  />
                </Button>
              </Row>
            </Filters>
          }
          leftElement={
            <Column gap="2rem">
              <LayoutIcon
                className={mode === ModeList.buy ? "active" : ""}
                onClick={() => setMode(ModeList.buy)}
              >
                <Icons.Buy />
              </LayoutIcon>
              {isAuth &&
              user?.blocks?.find((item) => item.banned.type == Banss.ban) ==
                undefined ? (
                <>
                  <LayoutIcon
                    className={mode === ModeList.sell ? "active" : ""}
                    onClick={() => setMode(ModeList.sell)}
                  >
                    <Icons.Sell />
                  </LayoutIcon>
                  <LayoutIcon
                    className={""}
                    onClick={() => setMode(ModeList.change)}
                  >
                    <Icons.OnSale />
                  </LayoutIcon>
                  <LayoutIcon
                    className={""}
                    onClick={() => setMode(ModeList.history)}
                  >
                    <Icons.MarketHistory />
                  </LayoutIcon>
                </>
              ) : null}
            </Column>
          }
        >
          {(mode == ModeList.buy || mode == ModeList.sell) &&
          windowInnerWidth < 1250 ? (
            <Filters params={{ width: "unset" }}>
              <UIKIT.DropDown
                onChange={setFoo}
                setUse={useClass}
                // useSort={useSortPrice}
                items={filters.map((item) => {
                  // setClassId(item.id);
                  return item.name;
                })}
                headParams={{
                  title: "Класс:",
                  icon: <TinyIcons.Arrow />,
                }}
              />
              {/* {console.log(_)} */}
              {_ !== undefined && _ !== "Все" && _ !== "Агенты" && _ !== "" ? (
                <UIKIT.DropDown
                  onChange={setFoo1}
                  items={filItem}
                  setUse={useClass}
                  headParams={{
                    title: "Тип:",
                    icon: <TinyIcons.Arrow />,
                  }}
                />
              ) : null}
              {mode == ModeList.buy ? (
                <Button
                  height="0"
                  variant="Sort"
                  params={{ width: "0", padding: "0" }}
                  color="Gray"
                  onClick={() => sortPrice()}
                >
                  <UIKIT.DropDown
                    useSort={useSortPrice}
                    onChange={null}
                    items={["pric"]}
                    headParams={{
                      title: "Цена:",
                      icon: <TinyIcons.Arrow />,
                    }}
                  />
                </Button>
              ) : null}
              <Row
                gap="3rem"
                fullWidth
                justifyContent="space-between"
                style={{
                  minWidth: windowInnerWidth > 1250 ? "38.1rem" : "",
                  maxWidth: windowInnerWidth > 1250 ? "43.2rem" : "",
                  height: "2.5rem",
                }}
              >
                <Button
                  height="0"
                  variant="Sort"
                  params={{ width: "0", padding: "0" }}
                  color="Gray"
                  onClick={() => sortRarity()}
                >
                  <UIKIT.DropDown
                    onChange={null}
                    useSort={useSortRarity}
                    items={["rar"]}
                    headParams={{
                      title: "Редкость:",
                      icon: <TinyIcons.Arrow />,
                    }}
                  />
                </Button>
              </Row>
            </Filters>
          ) : null}
          {windowInnerWidth < 1250 ? (
            <Column gap="2rem" direction="row">
              <LayoutIcon
                className={mode === ModeList.buy ? "active" : ""}
                onClick={() => setMode(ModeList.buy)}
              >
                <Icons.Buy />
              </LayoutIcon>
              {isAuth &&
              user?.blocks?.find((item) => item.banned.type == Banss.ban) ==
                undefined ? (
                <>
                  <LayoutIcon
                    className={mode === ModeList.sell ? "active" : ""}
                    onClick={() => setMode(ModeList.sell)}
                  >
                    <Icons.Sell />
                  </LayoutIcon>
                  <LayoutIcon
                    className={""}
                    onClick={() => setMode(ModeList.change)}
                  >
                    <Icons.OnSale />
                  </LayoutIcon>
                  <LayoutIcon
                    className={""}
                    onClick={() => setMode(ModeList.history)}
                  >
                    <Icons.MarketHistory />
                  </LayoutIcon>
                </>
              ) : null}
            </Column>
          ) : null}
          {/* <Button
            color="blue"
            variant="default"
            height="5rem"
            onClick={() => {
              newArrayMarket.map((item) => {
                CaseServise.SoldItem([item.id]);
              });
            }}
          >
            ПРОДАТЬ
          </Button> */}
          <Weapons isGrid={true}>{getItems()}</Weapons>
          <Modals.MarketplaceWeapon
            isActive={modal}
            setActive={setModal}
            mode={mode}
            priceUpdate={priceUpdate}
            marketInventory={marketInventory}
          />
        </Layout>
      ) : (
        <Layout
          headerElement={<div></div>}
          leftElement={
            <Column gap="2rem">
              <LayoutIcon
                className={mode === ModeList.buy ? "active" : ""}
                onClick={() => setMode(ModeList.buy)}
              >
                <Icons.Buy />
              </LayoutIcon>
              {isAuth &&
              user?.blocks?.find((item) => item.banned.type == Banss.ban) ==
                undefined ? (
                <>
                  <LayoutIcon
                    className={mode === ModeList.sell ? "active" : ""}
                    onClick={() => setMode(ModeList.sell)}
                  >
                    <Icons.Sell />
                  </LayoutIcon>
                  <LayoutIcon
                    className={mode === ModeList.change ? "active" : ""}
                    onClick={() => setMode(ModeList.change)}
                  >
                    <Icons.OnSale />
                  </LayoutIcon>
                  <LayoutIcon
                    className={mode === ModeList.history ? "active" : ""}
                    onClick={() => setMode(ModeList.history)}
                  >
                    <Icons.MarketHistory />
                  </LayoutIcon>
                </>
              ) : null}
            </Column>
          }
        >
          {windowInnerWidth < 1250 ? (
            <Column gap="2rem" direction="row">
              <LayoutIcon
                className={mode === ModeList.buy ? "active" : ""}
                onClick={() => setMode(ModeList.buy)}
              >
                <Icons.Buy />
              </LayoutIcon>
              {isAuth &&
              user?.blocks?.find((item) => item.banned.type == Banss.ban) ==
                undefined ? (
                <>
                  <LayoutIcon
                    className={mode === ModeList.sell ? "active" : ""}
                    onClick={() => setMode(ModeList.sell)}
                  >
                    <Icons.Sell />
                  </LayoutIcon>
                  <LayoutIcon
                    className={mode === ModeList.change ? "active" : ""}
                    onClick={() => setMode(ModeList.change)}
                  >
                    <Icons.OnSale />
                  </LayoutIcon>
                  <LayoutIcon
                    className={mode === ModeList.history ? "active" : ""}
                    onClick={() => setMode(ModeList.history)}
                  >
                    <Icons.MarketHistory />
                  </LayoutIcon>
                </>
              ) : null}
            </Column>
          ) : null}

          <Weapons isGrid={mode !== ModeList.history}>{getItems()}</Weapons>
          <Modals.MarketplaceWeapon
            isActive={modal}
            setActive={setModal}
            mode={mode}
            priceUpdate={() => priceUpdate}
            marketInventory={marketInventory}
          />
        </Layout>
      )}
    </MarketplaceContext.Provider>
  );
}
