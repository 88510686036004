import { ServerCard, RowServerCard } from "components/Cards";
import { Body, GridLayout, Head, HeadItem, RowLayout } from "./Styles";
import { IServer } from "models/Server";
import { Modals } from "components/Modal";
import { useMemo, useState } from "react";
import { CurrentServerContext } from "context/currentServer";
import { Player, PlayerAndBlock } from "interfaces/CategoryMap";

interface Props {
  servers: IServer[];
  layout?: number;
  players?: PlayerAndBlock;
}

export default function Servers({ servers = [], layout, players }: Props) {
  const [modal, setModal] = useState(false);
  const [server, setServer] = useState<IServer>({});
  const [playersInServer, setplayersInServer] = useState<Player[]>();
  const [idServer, setIdServer] = useState<number>();

  const serverLayMemo = useMemo(() => {
    return servers.map((item, index) => (
      <ServerCard
        servers={servers}
        map={item.map}
        max_players={item.max_players}
        players={item.players}
        type={item.type}
        ip={item.ip}
        id={item.id}
        port={item.port}
        server_name={item.server_name}
        key={index}
        image={item.image}
        play={players}
        onClick={() => {
          setServer(servers[index]);
          setModal(true);
          let j: Player[] = [];
          players?.servers.forEach((items) => {
            if (items.server.id == Number(item.id)) {
              j.push(items.player);
            }
          });
          setplayersInServer(j);
          setIdServer(Number(item.id));
          // console.log(playersInServer);
        }}
      />
    ));
  }, [servers]);

  const serverLox = useMemo(() => {
    return servers.map((item, index) => (
      <RowServerCard
        key={index}
        server_name={item.server_name}
        map={item.map}
        image={item.image}
        players={item.players}
        max_players={item.max_players}
        ip={item.ip}
        id={item.id}
        port={item.port}
        play={players}
        onClick={() => {
          setServer(servers[index]);
          setModal(true);
          setIdServer(Number(item.id));
          let j: Player[] = [];
          players?.servers.forEach((items) => {
            if (items.server.id == Number(item.id)) {
              j.push(items.player);
            }
          });
          setplayersInServer(j);
        }}
      />
    ));
  }, [servers]);

  return (
    <CurrentServerContext.Provider value={server}>
      {layout === 0 ? (
        <GridLayout count={servers.length}>{serverLayMemo}</GridLayout>
      ) : (
        <RowLayout>
          <Head>
            <HeadItem>Название сервера</HeadItem>
            <HeadItem>Карта</HeadItem>
            <HeadItem>Игроки</HeadItem>
            <HeadItem>Ip</HeadItem>
            <HeadItem>Действия</HeadItem>
          </Head>
          <Body className="customScroll">{serverLox}</Body>
        </RowLayout>
      )}
      <Modals.DashboardServer
        isActive={modal}
        setActive={setModal}
        player={playersInServer}
        play={players}
        id={idServer}
      />
    </CurrentServerContext.Provider>
  );
}
