import { TinyIcons } from "assets/tiny-icons";
import Layout from "components/Layout";
import Button from "components/UI/Button";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { HeaderWrap, Text, Title, Wrapper, WrapTitle } from "./Styles";

export default function Offer() {
  const navigate = useNavigate();
  const windowInnerWidth = document.documentElement.clientWidth;
  return (
    <Layout
      leftElement={<div />}
      headerElement={
        <HeaderWrap>
          {/* <UIKIT.ArrowBackMoney /> */}
          {/* <SubHeader>Договор – оферта</SubHeader> */}
          <Button
            variant="picker"
            height="5rem"
            onClick={() => navigate("/rules")}
          >
            Правила
          </Button>
          <Button variant="picker" height="5rem" className="active">
            Оферта
          </Button>
          <Button
            variant="picker"
            height="5rem"
            onClick={() => navigate("/rules/PrivatePolicy")}
          >
            Политика конфидициальности
          </Button>
          <Button
            variant="picker"
            height="5rem"
            onClick={() => navigate("/rules/contact")}
          >
            Контакты
          </Button>
        </HeaderWrap>
      }
    >
      <Helmet>
        <title>FREEZ-BLOOD - Оферта</title>
        <meta name="description" content={`FREEZ-BLOOD - Оферта`} />
      </Helmet>
      {windowInnerWidth < 1250 ? (
        <HeaderWrap>
          {/* <UIKIT.ArrowBackMoney /> */}
          {/* <SubHeader>Договор – оферта</SubHeader> */}
          <Button
            variant="picker"
            height="5rem"
            onClick={() => navigate("/rules")}
          >
            Правила
          </Button>
          <Button variant="picker" height="5rem" className="active">
            Оферта
          </Button>
          <Button
            variant="picker"
            height="5rem"
            onClick={() => navigate("/rules/contact")}
          >
            Контакты
          </Button>
        </HeaderWrap>
      ) : null}
      <Wrapper>
        <WrapTitle>Договор – оферта</WrapTitle>
        <Title>1. ОБЩИЕ ПОЛОЖЕНИЯ</Title>
        <Text>
          1.1 Настоящий публичный договор-оферта (далее Оферта) представляет
          собой официальное предложение Администрации Сайта freez-blood.com,
          далее именуемой «Исполнитель», по оказанию Услуг на игровых серверах
          Counter-Strike Global Offensive проекта Freezing-Blood Игрокам
          признаваемыми «Заказчиками» в соответствии с условиями настоящей
          Оферты.
        </Text>
        <Text>
          1.2 Заказчик (игрок) – физическое лицо, обратившееся с заказом к
          Исполнителю в соответствии с условиями настоящего Договора.
        </Text>
        <Text>
          1.3 Исполнитель (администрация) – физическое лицо, действующее как
          Индивидуальный предприниматель Барсамов Кирилл Валерьевич оказывающее
          услуги Заказчику по Договору.
        </Text>
        <Text>1.4. Стороны - Исполнитель и Заказчик.</Text>
        <Text>
          1.5 Услуги - Привилегии, кредиты либо иные преимущества на игровом
          сервере либо сайте.
        </Text>
        <Text>
          1.6 Сайт – информационный ресурс Исполнителя, размещенный в сети
          Интернет по адресу: https://freez-blood.com и его поддомены.
        </Text>
        <Text>
          1.7 Личный кабинет Заказчика – программный интерфейс на Сайте,
          предназначенный для удаленного взаимодействия Заказчика и Исполнителя
          в рамках Договора, и содержит информацию о Заказчике, принятии им
          условий оказания Услуг, перечень, объем и сроки оказания Услуг, иную
          необходимую информацию, доступную Заказчику после авторизации с
          использованием логина и пароля.
        </Text>
        <Text>
          1.8 Заказ – автоматически формируемый документ, определяющий набор
          Услуг, необходимых Заказчику. Заказ формируется путем заполнения
          необходимых форм в личном кабинете Заказчика на сайте Исполнителя –
          https://freez-blood.com.
        </Text>
        <Text>
          1.9 VIP – Система для внутриигровых привилегий, которая предоставляет
          преимущества игрокам.
        </Text>
        <Text>
          1.10 <TinyIcons.Credit />
          (кредиты) – Внутриигровой баланс, предназначенные исключительно для
          использования их в Игре/Сайте в целях получения доступа к
          дополнительному функционалу Игр путем обмена их на внутриигровые
          предметы или призы.
        </Text>
        <Text>
          1.11 Геймплей - игровой процесс с точки зрения игрока. Геймплей
          включает в себя разные содержательные аспекты компьютерной игры, в том
          числе технические, такие как внутриигровая механика, совокупность
          определённых методов
        </Text>
        <Text>
          1.12 Консоль - это текстовый интерфейс ввода и вывода для выполнения
          команд или изменения основных настроек игры.
        </Text>
        <Text>
          1.13 Техническая поддержка – система где оказывается техническая
          помощь по любым вопросам игрового проекта Freezing-Blood по URL
          https://freez-blood.com/support/.
        </Text>
        <Text>
          1.14 VAC - разработка компании Valve для предотвращения использования
          игроками читов (нечестных приемов игры).
        </Text>
        <Text>
          1.15 Правила проекта – свод разрешающих и запрещающих норм для игроков
          на серверах проекта Freezing-blood. Правила расположены по URL
          https://freez-blood.com/pages/mainrules.
        </Text>
        <Text>
          1.16 SteamID – это уникальный номер, который дается Вам при создании
          Steam аккаунта.
        </Text>
        <Text>
          1.17 Steam – онлайн-сервис цифрового распространения компьютерных игр
          и программ, разработанный и поддерживаемый компанией Valve.
        </Text>
        <Text>
          1.18 Аккаунт - учетная запись Пользователя, которая содержит данные о
          Пользователе и предоставляет Пользователю доступ к Платформе,
          информации о Пользователе, настройкам, игровым ценностям,
          статистическим показателям и иной подобной информации
        </Text>
        <Text>
          1.19 Пользоваться Сайтом и её функционалом, а также, получать доступ к
          Игре Заказчик вправе только через свою учетную запись на платформе
          Steam (оперируемой компанией Valve Corporation и/или ее
          аффилированными лицами). Компания не администрирует такую учетную
          запись Пользователя и не хранит персональные данные Пользователей,
          указанные ими при регистрации на платформе Steam.
        </Text>
        <Text>
          1.20 Доступ к Аккаунту Пользователя осуществляется при указании логина
          и пароля учетную запись на платформе Steam. Связка «логин-пароль»
          является ключом простой электронной подписи и позволяет считать
          действия, совершенные Заказчиком, совершенными им лично.
        </Text>
        <Text>
          1.21 Принятие условий Договора – действия Заказчика по принятию
          условий настоящего Договора, путем оплаты сформированного
          выставленного Исполнителем Договора-Счета, посредством наличных или
          безналичных денежных средств или электронных средств платежа. Принятие
          условий Договора считается состоявшимся при оплате Заказчиком
          настоящего Договора-Счета посредством оплаты наличными или
          безналичными денежными средствами, либо электронными средствами
          платежа.
        </Text>
        <Title>2. ПРЕДМЕТ ОФЕРТЫ</Title>
        <Text>
          2.1 Исполнитель обязуется предоставлять Заказчику услуги, а Заказчик
          обязуется оплатить эти услуги и надлежащим образом выполнять условия,
          установленные настоящей Офертой.
        </Text>
        <Text>
          2.2 Исполнитель оказывает услуги, а Заказчик — оплачивает их: <br />
          Заказчик выбирает услугу на странице https://freez-blood.com/store и
          любых вложенных страницах категории "Магазин".
        </Text>
        <Text>
          2.3 Исполнитель вправе изменить оферту и договор. Изменения вступают в
          силу через 1 день с момента публикации на сайте
          https://freez-blood.com/.
        </Text>
        <Title>3. СТОИМОСТЬ УСЛУГ И ПОРЯДОК РАСЧЕТОВ</Title>
        <Text>
          3.1. Стоимость и перечень услуг, предоставляемых Исполнителем по
          настоящей Оферте, размещены на странице https://freez-blood.com/store
        </Text>
        <Text>
          3.2. Оплата услуг по настоящему Договору осуществляется Заказчиком в
          валюте РФ путем внесения денежных средств на расчетный счет или в
          кассу Исполнителя.
        </Text>
        <Text>
          3.3. Датой надлежащего исполнения обязательств Заказчиком по оплате
          услуг Исполнителя будет считаться с момента получения уведомления о
          том, что услуга успешно выдана.
        </Text>
        <Text>
          3.4. Исполнитель вправе в одностороннем порядке пересматривать и
          изменять стоимость услуг, а также вводить новые услуги.
        </Text>
        <Text>
          3.5. Отчет об оказании услуг по настоящей Оферте Исполнитель формирует
          с помощью сервисов Сайта и делает его доступным в Личном кабинете
          Заказчика.
        </Text>
        <Text>
          3.6 Стоимость товара не включает комиссию банков и платёжных систем. В
          некоторых случаях комиссия может взиматься с Заказчика. Узнать
          комиссию можно при оплате покупки.
        </Text>
        <Title>4. ХАРАКТЕРИСТИКИ УСЛУГИ/ТОВАРА</Title>
        <Text>
          4.1 Описание услуги может расходиться с фактическими возможностями
          услуги. Точную информацию можно уточнить у администрации проекта
          Freez-Blood.
        </Text>
        <Text>
          44.2 Любая услуга может быть скорректирована и изменена для
          балансирования геймплея на игровом сервере.
        </Text>
        <Text>
          4.3 Срок услуги действует независимо от того, посещает Заказчик
          игровой сервер Исполнителя или нет.
        </Text>
        <Title>5. СРОКИ АКТИВАЦИИ УСЛУГ</Title>
        <Text>
          5.1 Кредиты после оплаты поступают на счет Заказчика Незамедлительно.
        </Text>
        <Text>
          5.2 Услуги приобретаемые по SteamID в магазине через личный кабинет по
          адресу https://freez-blood.com/store активируются сразу же после
          оплаты или после рестарта серверов.
        </Text>
        <Title>
          6. УСЛОВИЯ И ПОРЯДОК ПРЕДОСТАВЛЕНИЯ УСЛУГИ. ОБМЕН И ВОЗВРАТ, ЖАЛОБЫ
        </Title>
        <Text>
          6.1 Если Вы остались недовольны услугой, возможен возврат средств на
          баланс сайта, при условии, что срок услуги был не менее 30 дней и с
          момента оплаты не прошло более 14 дней. В таком случае, будет
          произведен перерасчет с учетом использованных дней.
        </Text>
        <Text>
          6.2 Каждый запрос на возврат средств рассматривается в поддержке на
          сайте https://freez-blood.com/support
        </Text>
        <Text>
          6.3 Если Вы хотите обменять текущую услугу на другую, то это возможно
          осуществить с доплатой в течение недели, после покупки основной услуги
          через систему поддержки на сайте.
        </Text>
        <Text>
          6.4 Услуги предоставляемые на срок до 30 дней не переносятся на другие
          сервера и не подлежат возврату на баланс сайта.
        </Text>
        <Text>
          6.5 Возврат средств за приобретение Кредитов возможен в случаи их
          неработоспособности.
        </Text>
        <Text>
          6.6 Если игровой аккаунт был заблокирован системой VAC либо нашей
          внутриигровой системой банов, то возврат средств за услугу, а так же
          её перенос невозможен.
        </Text>
        <Text>
          6.7 Перенос услуг на другие Аккаунты или смена SteamID на услугах
          невозможна.
        </Text>
        <Text>
          6.8 В случае нарушений правил проекта https://freez-blood.com/rules,
          все услуги могут быть аннулированы.
        </Text>
        <Text>
          6.9 Перенос услуг на другие Аккаунты или смена SteamID на услугах
          невозможна.
        </Text>
        <Text>
          6.10 В случае нарушений правил проекта https://freez-blood.com/rules,
          все услуги могут быть аннулированы.
        </Text>
        <Title>7. ОТВЕТСТВЕННОСТЬ СТОРОН</Title>
        <Text>
          7.1 Исполнитель обязан оказывать услуги качественно, своевременно и в
          полном соответствии с условиями Договора.
        </Text>
        <Text>
          7.1 Исполнитель не гарантирует доступность игрового сервера в случае
          DDOS атак.
        </Text>
        <Text>
          7.2 Исполнитель не гарантирует абсолютную бесперебойность или
          безошибочность в оказании услуг.
        </Text>
        <Text>
          7.3 Исполнитель предпринимает все разумные усилия и меры в целях
          минимизации потерь от таких ошибок.
        </Text>
        <Text>
          7.4 Исполнитель не несет ответственности за непредставление услуг во
          время проведения необходимых плановых профилактических и ремонтных
          работ на сервере.
        </Text>
        <Text>
          7.5 Заказчик несет ответственность за сохранность своего Аккаунта, а
          также за убытки, которые могут возникнуть по причине
          несанкционированного его использования.
        </Text>
        <Title>8. ОБСТОЯТЕЛЬСТВА НЕОПРЕДЕЛИМОЙ СИЛЫ</Title>
        <Text>
          8.1 Стороны освобождаются от ответственности в случае, когда
          невозможность исполнения возникла по обстоятельствам, за которые ни
          одна из Сторон не отвечает (форс-мажор).
        </Text>
        <Text>
          8.2 Сторона, которая не может исполнить своего обязательства, должна
          известить другую Сторону о препятствии и его влиянии на исполнение
          обязательств по Договору в течение 10 (десяти) календарных дней с
          момента возникновения этих обстоятельств.
        </Text>
        <Title>9. СРОК ДЕЙСТВИЯ И ПОРЯДОК РАСТОРЖЕНИЯ</Title>
        <Text>
          9.1. Договор действует с момента акцепта — пополнения баланса и оплаты
          привилегии из раздела "Магазин" сайта https://freez-blood.com/store.
          Если Заказчик приобрел услугу на проекте Freez-Blood, договор
          действует до последнего дня оплаченной услуги, без автоматической
          пролонгации.
        </Text>
        <Text>
          9.2 Оферта может быть расторгнута по инициативе любой из Сторон в
          любое время.
        </Text>
        <Text>
          9.3 Электронные письма, и тикеты отправленные с адресов Заказчиков,
          указанных в личном кабинете, признаются подписанными простой
          электронной подписью — аналогом собственноручной подписи.
        </Text>
        <Text>10. РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ</Text>
        <Text>ИП Барсамов Кирилл Валерьевич</Text>
        <Text>ИНН: 300403079435</Text>
        <Text>ОГРН: 322300000005352</Text>
        <Text>Адрес почты: support@barsamov.ru</Text>
        <Text></Text>
        <Text></Text>
        <Text>Редакция от 22 февраля 2023 года</Text>
      </Wrapper>
    </Layout>
  );
}
