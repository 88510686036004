import styled from "styled-components";

export const Block = styled.div`
	width: 100%;
	height: fit-content;
	border-radius: 2rem;
	padding: 2rem;
	border: .1rem solid ${props => props.theme.colors.blue.accent};
	${props => props.theme.fonts.GothamRegular20};
	color: ${props => props.theme.colors.white.white100};
	line-height: 130%;
`

export const Link = styled.p`
	align-self: flex-start;
	${props => props.theme.fonts.GothamRegular20};
	color: ${props => props.theme.colors.white.white100};
	a {
		transition: all .3s;
		${props => props.theme.mixins.blueColor};
	}
`