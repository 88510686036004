import axios, { AxiosResponse } from "axios";
import { StickerRowProps } from "components/Modal/SkinChanger/StickerRow/StickerRow";
import { ApplyParams } from "pages/Profile/Tabs/SkinChanger/SkinChangerState";
import { endpoints, url, urlSC } from "API";

export class SkinChangerService {
  static async getData() {
    try {
      const response = await axios.get(urlSC + endpoints.skinchanger.data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("Authorization"),
        },
      });
      return response.data;
    } catch (error: any) {
      console.error("Error in getData:", error.message);
      throw error;
    }
  }

  static async getSkins(id: number) {
    // console.log("fds", id);
    try {
      const response = await axios.get(
        `${urlSC}${endpoints.skinchanger.skins}?item_id=${id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Authorization"),
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.error("Error in getSkins:", error.message);
      throw error;
    }
  }

  static async getAgents() {
    try {
      const response = await axios.get(
        `${urlSC}${endpoints.skinchanger.skins}/agents`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Authorization"),
          },
        }
      );
      //console.log("agent: ", response);

      return response.data;
    } catch (error: any) {
      console.error("Error in agents:", error.message);
      throw error;
    }
  }

  static async getStickers(
    page: number = 1,
    filter?: string
  ): Promise<{
    data: StickerRowProps[];
    number_of_pages: number;
    page: number;
    per_page: number;
    total: number;
  }> {
    let link = `${urlSC}${endpoints.skinchanger.stickers}?page=${page}`;

    if (filter) {
      link += `&name=${filter}`;
    }

    try {
      const response: AxiosResponse<{
        data: StickerRowProps[];
        number_of_pages: number;
        page: number;
        per_page: number;
        total: number;
      }> = await axios.get(link, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("Authorization"),
        },
      });

      return response.data;
    } catch (error: any) {
      console.error("Error in getStickers:", error.message);
      throw error;
    }
  }

  static async apply(params: ApplyParams) {
    //console.log(urlSC + endpoints.skinchanger.apply);
    try {
      const response = await axios.post(
        urlSC + endpoints.skinchanger.apply,
        { ...params },
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Authorization"),
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.error("Error in apply:", error.message);
      throw error;
    }
  }

  static async getStickerId(ids: number[]) {
    //console.log(urlSC + endpoints.skinchanger.apply);
    try {
      const response = await axios.post(
        urlSC +
          endpoints.skinchanger.skins +
          endpoints.skinchanger.stickersID +
          endpoints.skinchanger.id,
        { ids: ids },
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Authorization"),
          },
        }
      );
      return response.data.data;
    } catch (error: any) {
      console.error("Error in apply:", error.message);
      throw error;
    }
  }

  static async applyAgent(params: ApplyParams) {
    //console.log(urlSC + endpoints.skinchanger.skins + "/agent" + "/apply");
    try {
      const response = await axios.post(
        urlSC + endpoints.skinchanger.skins + "/agent" + "/apply",
        { ...params },
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Authorization"),
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.error("Error in apply:", error.message);
      throw error;
    }
  }

  static async getCategories() {
    try {
      const response = await axios.get(
        urlSC + endpoints.skinchanger.categories,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Authorization"),
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.error("Error in getCategories:", error.message);
      throw error;
    }
  }

  static async getAuthToken() {
    try {
      const response = await axios.get(url + endpoints.skinchanger.authToken, {
        withCredentials: true,
      });
      return response.data.token;
    } catch (error: any) {
      console.error("Error in getAuthToken:", error.message);
      throw error;
    }
  }

  // static async getSkinChangerSettings(id: number) {
  //   try {
  //     const response = await axios.get(
  //       `${urlSC}${endpoints.skinchanger.skins}?item_id=${id}`,
  //       {
  //         headers: {
  //           Authorization: "Bearer " + localStorage.getItem("authToken"),
  //         },
  //       }
  //     );

  //     return response.data;
  //   } catch (error: any) {
  //     console.error("Error in getSkinChangerSettings:", error.message);
  //     throw error;
  //   }
  // }
}
