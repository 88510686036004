import { TinyIcons } from 'assets/tiny-icons'
import Checkbox from 'components/UI/Checkbox'
import DropDown from 'components/UI/DropDown'
import Input from 'components/UI/Input'
import Select from 'components/UI/Select'
import Toggle from 'components/UI/Toggle'
import { useState } from 'react'

export default function Debug() {

	const [foo, setFoo] = useState(null)
	
	return (
		<div style={{ margin: '20rem', display: 'flex', flexDirection: 'column', gap: '30px' }}>
			<DropDown
				items={['first', 'second', 'dthghfghfhf']}
				onChange={setFoo}
				headParams={{
					title: 'Класс:',
					icon: <TinyIcons.Arrow />
				}}
			/>
			<Input
				label='Ugabuga'
				placeholder='sdasds'
			/>
			<Select
				onChange={setFoo} 
				items={["Raz", 'Dva']}
			/>
			<Toggle label='Ugabuga' />
			<Checkbox
				label='Ugabuga'
			/>
		</div>
	)
}
