import Chat from "modules/Chat";
import React, { memo, useEffect, useMemo, useState } from "react";
import Header from "../Header";
import {
  AsideITEMBlock,
  BlockItemIcon,
  ChatSpan,
  ChatWrapper,
  Container,
  FooterMain,
  Item,
  ItemIcons,
  LeftItemFooter,
  MediumSpan,
  MediumSpanLink,
  RightitemFooter,
  StyledLayout,
  ToggleChat,
  Wrapper,
  WrapSmallWidth,
} from "./Styles";

import { appSocket } from "App";
import Toast from "components/UI/Toast";
import { useAppSelector } from "hooks/useAppSelector";

import { ChatService } from "API/ChatService";
import { Icons } from "assets/icons";
import { TinyIcons } from "assets/tiny-icons";
import Pagination from "components/PagePagination/PagePagination";
import { asideItems, footerAsideItem } from "consts/aside";
import { IMessage } from "models/Message";
import Image from "../Image";

interface Props {
  children: any;
  headerElement?: React.ReactElement | string;
  footerElement?: React.ReactElement | string;
  leftElement?: React.ReactElement | string;
  activePages?: boolean;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  total?: number;
  page?: number;
  nonChat?: boolean;
  perPage?: number;
}

function Layout({
  children,
  activePages,
  total,
  page,
  setPage,
  nonChat,
  perPage,
  headerElement,
  footerElement,
  leftElement,
}: Props) {
  const { user } = useAppSelector((state) => state.user);

  const [historyChatOnly, setHistoryChatOnly] = useState<IMessage[]>([]);

  useEffect(() => {
    appSocket.on("recMessage", (data: IMessage) => {
      setHistoryChatOnly((prevHistory) => {
        const newHistory = [...prevHistory, data];
        if (newHistory.length > 100) {
          newHistory.shift(); // Удаляет первый элемент массива, если длина превысила 100
        }
        return newHistory;
      });
    });
    async function Mes() {
      setHistoryChatOnly(await ChatService.getHistory());
    }
    Mes();
  }, [1]);

  // console.log("rerender");

  const [isChatActive, setChatActive] = useState(false);
  const windowInnerWidth = document.documentElement.clientWidth;
  const [activMod, setActivMod] = useState(false);

  return (
    <StyledLayout>
      <Header>{headerElement}</Header>
      <Container isChatActive={isChatActive}>
        {windowInnerWidth > 1250 ? leftElement : <div />}

        {children}
        {user ? (
          <ChatWrapper isChatActive={isChatActive}>
            <Chat
              isActive={isChatActive}
              items={historyChatOnly}
              source={appSocket}
              setActive={setChatActive}
              isFileInput={true}
              // onlineChat={onlineChat}
            />
            <ToggleChat
              onClick={async () => {
                setChatActive(!isChatActive);
              }}
            >
              <TinyIcons.Chat />
              <ChatSpan>Чат</ChatSpan>
            </ToggleChat>
          </ChatWrapper>
        ) : null}
      </Container>
      {activePages ? (
        <Pagination
          total={total}
          page1={page}
          perPage={perPage}
          setPage={setPage}
        />
      ) : null}
      {footerElement}
      <FooterMain>
        <LeftItemFooter>
          <Image
            src="/static/images/logo.svg"
            alt="Logo"
            width="30px"
            height="40px"
          />
          <MediumSpan>Copyright 2019-2024</MediumSpan>
          <MediumSpan>ИП Барсамов Кирилл Валерьевич</MediumSpan>
          <ItemIcons>
            <BlockItemIcon
              onClick={() => window.open("https://discord.gg/freez-blood")}
            >
              <Icons.Discord />
            </BlockItemIcon>
            <BlockItemIcon
              onClick={() =>
                window.open("https://youtube.com/@fb-life?si=iN-hrkth-4iTa8fk")
              }
            >
              <Icons.Youtube />
            </BlockItemIcon>
            <BlockItemIcon
              onClick={() => window.open("https://t.me/freezblood")}
            >
              <Icons.Telegram />
            </BlockItemIcon>
            <BlockItemIcon
              onClick={() => window.open("https://vk.com/freezblood")}
            >
              <Icons.VK />
            </BlockItemIcon>
          </ItemIcons>
          <Icons.Banc />
        </LeftItemFooter>

        <RightitemFooter>
          <AsideITEMBlock>
            {footerAsideItem.map((item, index) => {
              return (
                <MediumSpanLink to={item.link} key={index}>
                  {item.label} <Icons.IconHref />
                </MediumSpanLink>
              );
            })}
          </AsideITEMBlock>
        </RightitemFooter>
      </FooterMain>
      <Toast params={{ autoClose: 1000, limit: 3 }} />
      <WrapSmallWidth active={activMod}>
        <div onClick={() => setActivMod(!activMod)}>
          <TinyIcons.Arrow />
        </div>
        <Wrapper>
          {asideItems.map((item, index) => (
            <Item to={item.link} key={index}>
              {item.label}
            </Item>
          ))}
        </Wrapper>
      </WrapSmallWidth>
    </StyledLayout>
  );
}

export default memo(Layout);
