import { useId } from 'react'
import { Container, Label, ToggleBlock } from './Styles'

interface Props {
	label: string;
	value?: any;
	onChange?: (value: any) => any;
	callback?: (value: any) => any;
	params?: {
		position?: 'left' | 'right';
		alignSelf?: string
	};
}

export default function Toggle({ label, value, onChange, callback, params = { position: 'right' } }: Props) {

	const id = useId()

	function handleToggle(value: boolean) {
		onChange && onChange(value)
		callback && callback(value)
	}

	return (
		<Container style={{ alignSelf: params.alignSelf && params.alignSelf }}>
			<Label htmlFor={id}>
				{params?.position === 'left' &&
					<>
						<input
							type="checkbox"
							id={id}
							value={value}
							onChange={(event) => handleToggle(event.target.checked)}
						/>
						<ToggleBlock id={id} />
					</>
				}
				{label}
				{params?.position === 'right' &&
					<>
						<input
							type="checkbox"
							id={id}
							value={value}
							onChange={(event) => handleToggle(event.target.checked)}
						/>
						<ToggleBlock id={id} />
					</>
				}
			</Label>
		</Container>
	)
}
