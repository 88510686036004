import { endpoints, url } from "API";
import axios from "axios";
import { ShopService } from "./ShopService";

export class topService {
  static async getHistory() {
    try {
      const response = await axios.post(url + endpoints.stats.top, {
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + (await ShopService.getAuthToken()),
        },
      });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }
}
