import { Card, Container, Map, Online, Type } from "./Styles";
import { IServer } from "models/Server";
import Image from "components/Image";
import { TinyIcons } from "assets/tiny-icons";
import ActionButton from "components/UI/ActionButton";
import ServerPlayers from "components/ServerPlayers";
import { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";

export function ServerCard({
  map,
  max_players,
  players,
  type,
  ip,
  id,
  port,
  image,
  server_name,
  play,
  servers,
  ...props
}: IServer) {
  const [copied, setCopied] = useState(false);

  const [playUser, setPlayUser] = useState<number>(0);
  const timeoutRef = useRef<number | null>(null);
  // console.log(play);
  useMemo(() => {
    let i = 0;
    play?.servers.forEach((item) => {
      if (item.server.id == Number(id)) {
        i++;
      }
    });
    setPlayUser(i);
  }, [play, servers]);

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  return (
    <Card {...props}>
      <div className="bg" />
      <Image src={image || ""} alt="server" />
      <Container>
        <Map>{server_name}</Map>
        <Type>
          {map}
          <ActionButton
            onClick={(e) => {
              window.location.replace(`steam://connect/${ip}:${port}`);
              e.stopPropagation();
            }}
          >
            <TinyIcons.Play />
          </ActionButton>
          <ActionButton
            onClick={(e) => {
              handleCopy(`connect ${ip}:${port}`);
              toast("Адрес сервера скопирован");
              e.stopPropagation();
            }}
          >
            <TinyIcons.Copy />
          </ActionButton>
        </Type>
        <Online>
          <ServerPlayers players={playUser} max_players={max_players || 0} />
        </Online>
      </Container>
    </Card>
  );
}
