import Column from 'components/Column'

import { Template, Title } from '../Styles'
import Avatar from 'components/Avatar'
import FileUpload from 'components/FileUpload/FileUpload'
import Button from 'components/UI/Button'
import { useAppSelector } from 'hooks/useAppSelector'

export default function AvatarChange() {

	const { user } = useAppSelector(state => state.user?.user)

	return (
		<Template>
			<Column justifyContent='center' alignItems='center' gap='3rem'>
				<Title>
					Аватар
				</Title>
				<Avatar size='big' src={user?.avatar} />
				<FileUpload>
					<Button height='5rem' variant='picker'>
						Выберете файл
					</Button>
				</FileUpload>
				<Button
					height='5rem'
					variant='default'
					color='blue'
					style={{ padding: '1.5rem 9rem', borderRadius: '2rem' }}
					disabled
				>
					Сохранить
				</Button>
			</Column>
		</Template>
	)
}
