import { LazyImage } from "./Styles";

interface ImageProps {
  key?: string;
  src: string;
  alt: string;
  height?: string;
  width?: string;
  caption?: string;
  className?: string;
  maxWidth?: string;
}

export default function Image({
  key,
  src,
  alt,
  width,
  height,
  className,
  maxWidth,
  ...props
}: ImageProps) {
  return (
    <LazyImage
      key={key}
      alt={alt}
      height={height}
      src={src}
      width={width}
      className={className}
      maxWidth={maxWidth}
      {...props}
    />
  );
}
