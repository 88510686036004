import { SkinChangerService } from "API/SkinChangerService";
import { SocketProvider, socketListener } from "API/SocketProvider";
import { Modals } from "components/Modal";
import HistoryState from "components/Modal/History/HistoryState";
import ShopState from "components/Modal/Shop/ShopState";
import TradeState from "components/Modal/Trade/TradeState";
import { useActions } from "hooks/useActions";
import { useAppSelector } from "hooks/useAppSelector";
import { Exceptions } from "interfaces/Roulette";
import {
  Trade,
  TradeInventory,
  TradeStatus,
  TradeUpdate,
} from "interfaces/Trade";
import { observer } from "mobx-react-lite";
import ChatState from "modules/Chat/ChatState";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import AppRoutes from "./components/AppRoutes";
import Aside from "./components/Aside";
import { GlobalStyles } from "./styles/GlobalStyles";

declare global {
  interface Window {
    socket: any;
    tradeUuid: string;
  }
}

enum MarketAction {
  Update = "UPDATE",
  Delete = "DELETE",
  Add = "ADD",
}
// export const appSocket = io("http://localhost:5000", {
// export const appSocket = io("https://api.freez-blood.com", {
export const appSocket = io("https://api.fb-craft.ru", {
  withCredentials: true,
});
window.socket = appSocket;
// console.log(appSocket);

function App() {
  const { fetchServers, setShowModal, setHistoryShowModal, setBalance } =
    useActions();
  const navigate = useNavigate();

  const { isAuth, user, userData } = useAppSelector((state) => state.user);

  function extractNumbersFromUrl(url: string) {
    const match = url.match(/\/(\d+)(\/|$)/); // регулярное выражение для поиска цифр после последнего слэша

    if (match) {
      return match[1]; // match[1] содержит первую группу захвата, то есть цифры
    } else {
      return null; // Если цифры не найдены, возвращаем null
    }
  }

  useEffect(() => {
    fetchServers();

    const socketProvider = new SocketProvider();

    appSocket.on("error", (error) => {
      // console.log("error", error.message);
      toast.error(error.message);
    });

    appSocket.on(socketListener.tradeData, (data: TradeInventory) => {
      if (data.status == TradeStatus.NotStarted) {
        TradeState.setConfirmed(true, data);
      }
      if (data.status == TradeStatus.NoConfirmed) {
        navigate("/TradeRoom");
      }

      HistoryState.setLive(data);
    });

    appSocket.on("online", (data) => {
      // console.log(data);

      ChatState.setNewOnline(data.users);
      // console.log("on");
      // console.log(data);
    });

    appSocket.on(
      socketListener.updateBalance,
      (data: { virtual_balance: number; balance: number }) => {
        // console.log("updateBalance", data);
        // console.log(data);
        setBalance({ real: user.balance.real, virtual: data.virtual_balance });
      }
    );
    // console.log(user.balance.real);

    // appSocket.on(so)

    appSocket.on(socketListener.trade, (data: Trade) => {
      // console.log(
      //   `[socket on] trade!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!: `,
      //   {
      //     data,
      //   }
      // );
      // console.log("trade", data);
      // HistoryState.setLive([data]) //старые трейды
      HistoryState.setLive(data);
      // TradeState.setShowModal(true);
      TradeState.setTradeData(data);
      navigate("/TradeRoom");

      //console.log("Первая замена: ", { data });
    });

    appSocket.on(socketListener.update, (data: TradeUpdate) => {
      // console.log(
      //   `[socket on] update: ффффффффффффффффффффффффффффффффффффффффффффффффффффффффффффффффффффффффф`,
      //   {
      //     data,
      //   }
      // );
      // console.log("update", data);

      TradeState.setTradeData({
        ...TradeState.tradeData,
        ...data,
      });

      //console.log("Вторая замена: ", { data });
    });

    appSocket.on(socketListener.confirmation, (data: TradeUpdate) => {
      // console.log(`[socket on] confirmation: `, {
      //   data,
      // });
      // console.log("confirmation", data);
    });
    appSocket.on("exception", (data: { type: Exceptions }) => {
      // setHistoryChatOnly((prevHistory) => [...prevHistory, data]);
      //  console.log(data);
      switch (data.type) {
        case Exceptions.ACTIVE_TRADE:
          toast.error("Завершите обмен перед ставкой");
          break;

        case Exceptions.BET_ON:
          toast.error("Дождитесь завершения ставки в Рулетке");
          break;
        case Exceptions.INVENTORY_MISS:
          toast.error("Данного предмета  нет у Вас в инвентаре");
          break;
        case Exceptions.ITEMS_ON_MARKET:
          toast.error("Выбранный предмет продается на тп");
          break;
        case Exceptions.MAX_PLAYERS_LIMIT:
          toast.error("Максимальное кол-во участников");
          break;
        case Exceptions.NOT_ENOUGH_BALANCE:
          toast.error("Недостаточно баланса");
          break;
        case Exceptions.TIME_OUT:
          toast.error("Время ставки вышло");
          break;
        case Exceptions.UNATHORIZED:
          toast.error("Вы неавторизованы");
          break;

        case Exceptions.UNKNOWN_ERROR:
          toast.error("Непредвиденная ошибка");
          break;
        case Exceptions.TradeEnd:
          toast.error("Обмен завершен");
          break;
        case Exceptions.ItemLocked:
          toast.error("Вы на 2 стадии подтверждения обмена");
          break;
        case Exceptions.ActiveTrade:
          toast.error("На данный момент идет активный обмен");
          break;

        case Exceptions.ItemNotFound:
          toast.error("Предмет не был найден");
          break;
        case Exceptions.Forbidden:
          toast.error("Доступ запрещен");
          break;
        case Exceptions.TradeSend:
          toast.error("У вас уже есть отправленный обмен");
          break;
        case Exceptions.ItemLimit:
          toast.error("Макмимальное кол-во скинов 32 скинов");
          break;
        case Exceptions.ErrorOccured:
          toast.error("Непредвиденная ошибка");
          break;
        case Exceptions.ImATeapot:
          toast.error("Продажа запрещена во время обмена");
          break;
        case Exceptions.BanProfile:
          toast.error("У вас активная блокировка");
          break;
      }
    });

    appSocket.on(socketListener.partnerConfirmed, (data: TradeUpdate) => {
      // console.log(`[socket on] partnerConfirmed: `, {
      //   data,
      // });
      // console.log("partnerConfirmed", data);
    });

    appSocket.on(
      socketListener.finish,
      (data: { tradeFinish: boolean; uuid: string }) => {
        // console.log(`[socket on] finish: `, {
        //   data,
        // });
        // console.log("finish", data);
        // TradeState.setShowModal(false);
        TradeState.setSecondModal(true);
        TradeState.setSecondType("success");
        TradeState.setUUID(data.uuid);
        navigate("/");
        // HistoryState.setLive([])
      }
    );

    appSocket.on(
      socketListener.tradeCanceled,
      (data: { cancel: boolean; uuid: string; status: TradeStatus }) => {
        // console.log("tradeCanceled", data);
        // console.log(`[socket on] tradeCanceled: `, {
        //   data,
        // });
        // console.log(this.status);
        // TradeState.setShowModal(false);
        if (data.status < 6) {
          TradeState.setSecondModal(true);
          // window.location.reload();
        }

        TradeState.setSecondType("cancel");
        TradeState.setUUID(data.uuid);
        HistoryState.deleteLive(data.uuid);
        var paramsString = window.location.href;
        // console.log(paramsString);
        if (paramsString.includes("TradeRoom")) {
          navigate("/");
        }
      }
    );

    appSocket.on(
      socketListener.changeTradeStatus,
      (data: { status: TradeStatus }) => {
        // console.log("changeTradeStatus", data);
        // console.log(`[socket on] changeTradeStatus: `, {
        //   data,
        // });

        TradeState.setTradeData({
          ...TradeState.tradeData,
          ...data,
        });

        //console.log("Третья замена: ", { data });
      }
    );
    const fetchToken = async () => {
      const authToken = await SkinChangerService.getAuthToken();
      // console.log(authToken);
      // console.log(authToken.token);
      if (authToken == undefined) {
        localStorage.setItem("Authorization", "");
      } else if (authToken == "") {
        localStorage.setItem("Authorization", "");
      } else {
        localStorage.setItem("Authorization", authToken);
      }

      // console.log(localStorage.getItem("Authorization"));
    };

    fetchToken();

    // setBalance([2, 2]);
  }, []);

  // useEffect(() => {
  //   console.log(document.visibilityState);
  // }, [document.]);

  return (
    <div className="App customScroll">
      <GlobalStyles
        redUser={
          extractNumbersFromUrl(window.location.href) == "76561198799170541" ||
          extractNumbersFromUrl(window.location.href) == "76561198842913345"
        }
        purpleUser={
          extractNumbersFromUrl(window.location.href) == "76561199206698793"
        }
      />
      <Aside />

      <AppRoutes />
      <Modals.TradeConfirm
        isActive={TradeState.isSecondModal}
        type={TradeState.isSecondType}
      />
      {/* <Modals.Trade
        isActive={TradeState.isShowModal}
        setActive={(status: boolean) => TradeState.setShowModal(status)}
      /> */}
      <Modals.History
        isActive={HistoryState.isShowModal}
        setActive={(status: boolean) => HistoryState.setShowModal(status)}
      />
      {/* <EmailLog isActive={isActive} setActive={setActive} /> */}
      {/* <Trades /> */}
      <Modals.Shop
        isActive={ShopState.isShowModal}
        setActive={(status: boolean) => ShopState.setShowModal(status)}
      />
    </div>
  );
}

export default observer(App);

//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!! ОСТАВЬ НАДЕЖДЫ ВСЕХ СЮДА ВХОДЯЩИХ !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
