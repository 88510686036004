import { TinyIcons } from "assets/tiny-icons";
import { IReplenishment } from "models/Replenishment";
import { useState } from "react";
import { Banner, Condition, Container, Info, Text } from "./Styles";

export default function ReplenishmentCard({
  text,
  condition,
  banner,
  active,
  wd,
  percent,
}: IReplenishment) {
  return (
    <Container active={active || false}>
      <Info>
        {wd ? <TinyIcons.Rus /> : <TinyIcons.World />}
        <Condition>{percent}%</Condition>
        <Condition> {condition}р</Condition>
        {/* <Text>{text}</Text> */}
        {/* <Condition>{condition}</Condition> */}
      </Info>
      <Banner height="60px" width="" src={banner} alt="banner" />
      <Text>{text}</Text>
    </Container>
  );
}
