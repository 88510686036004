import { url, endpoints } from "API";
import axios from "axios";

export class ContractServise {
  static async getInventory(
    page: number,
    perPage: number,
    rarity: number,
    id: number
  ) {
    try {
      const response = await axios.get(url + endpoints.contract.contract, {
        params: {
          page: page,
          perPage: perPage,
          rarity: rarity,
          id: id,
        },

        withCredentials: true,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("Authorization"),
        },
      });
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async PostNewScin(rarity: number, array?: number[]) {
    try {
      const response = await axios.post(
        url + endpoints.contract.create,
        {
          ids: array,
          rarity: rarity,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Authorization"),
          },
        }
      );
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }
}
