import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "API";
import { AuthState } from "models/Auth";
import { IWeaponCard } from "models/WeaponCard";
import { IUser } from "../../models/User";

const initialState: AuthState = {
  isAuth: false,
  user: {} as IUser,
  anotherUser: {} as IUser,
  userData: {} as IUser,
  friends: [],
  friendRequests: [],
  friendSent: [],
  inventory: [],
  marketInventory: [],
  serverInventory: [],
  personalFriends: [],
};

export const fetchLogin = createAsyncThunk("user/fetchLogin", async () => {
  const response = await API.UserService.getProfile();
  const s = await API.UserService.getProfile();
  return s;
});

export const fetchFriends = createAsyncThunk(
  "user/fetchFriends",
  async (steamid64: string) => {
    const response = await API.UserService.getFriends(steamid64);
    return response.data;
  }
);

export const fetchFriendRequests = createAsyncThunk(
  "user/fetchFriendRequests",
  async (steamid64: string) => {
    const response = await API.UserService.getFriendRequests(steamid64);
    return response.data;
  }
);

export const fetchFriendSent = createAsyncThunk(
  "user/fetchFriendSent",
  async (steamid64: string) => {
    const response = await API.UserService.getFriendSent(steamid64);
    return response.data;
  }
);

export const fetchInventory = createAsyncThunk(
  "user/fetchInventory",
  async ({
    steamid64,
    page,
    classId,
    weapon,
    perPage,
    raritySort,
  }: {
    steamid64: string;
    page: number;
    classId?: number;
    perPage: number;
    weapon?: number;
    raritySort?: string;
  }) => {
    const response = await API.UserService.getInventory(
      steamid64,
      page,
      classId,
      weapon,
      perPage,
      raritySort
    );
    return response;
  }
);

export const fetchMarketInventory = createAsyncThunk(
  "user/fetchMarketInventory",
  async ({
    page,
    classId,
    weapon,
    perPage,
    raritySort,
  }: {
    page: number;
    classId?: number;
    perPage: number;
    weapon?: number;
    raritySort?: string;
  }) => {
    const response = await API.MarketplaceService.MarketInventory(
      page,
      classId,
      weapon,
      perPage,
      raritySort
    );
    return response;
  }
);

export const fetchInventoryWithCallback = createAsyncThunk(
  "user/fetchInventory",
  async ({ steamid64, callback }: { steamid64: string; callback: any }) => {
    const response = await API.UserService.getInventory(steamid64);
    if (callback) {
      await callback(response.data);
    }
    return response.data;
  }
);

export const fetchServerInventory = createAsyncThunk(
  "user/fetchServerInventory",
  async ({ steamid64, callback }: { steamid64: string; callback: any }) => {
    const response = await API.UserService.getServerInventory(steamid64);
    if (callback) {
      await callback(response.data);
    }
    return response.data;
  }
);

export const fetchInventoryToServer = createAsyncThunk(
  "user/fetchInventorytoServer",
  async (data: { steamid64: string; skins: IWeaponCard[] }) => {
    const response = await API.UserService.sendInventoryToServer(
      data.steamid64,
      data.skins
    );
    return response.data;
  }
);

export const fetchInventoryFromServer = createAsyncThunk(
  "user/fetchInventoryFromServer",
  async (data: { steamid64: string; skins: IWeaponCard[] }) => {
    const response = await API.UserService.sendInventoryFromServer(
      data.steamid64,
      data.skins
    );
    return response.data;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    filterMarketInventory: (state, action) => {
      state.marketInventory = state.marketInventory.filter(
        (item) => item.id !== action.payload
      );
    },
    setAnotherUser: (state, action) => {
      state.anotherUser = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setBalance: (state, action) => {
      state.user.balance = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setAdmin: (state, action) => {
      state.user = { ...state.user, isAdmin: action.payload };
    },
    setInventory: (state, action) => {
      state.inventory = action.payload;
    },
    setServerInventory: (state, action) => {
      state.serverInventory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLogin.fulfilled, (state, action) => {
      if (1) {
        state.isAuth = true;
        state.user = { ...action.payload.data, isAdmin: true };
        localStorage.setItem(
          "your_steamid64",
          action.payload.data.user.steamid64
        );
      }
    });
    builder.addCase(fetchFriends.fulfilled, (state, action) => {
      if (action.payload) {
        state.friends = action.payload || [];
        state.personalFriends = action.payload || [];

        //console.log(action.payload)
      }
    });
    builder.addCase(fetchFriendRequests.fulfilled, (state, action) => {
      if (action.payload) {
        state.friendRequests = action.payload || [];

        //console.log(action.payload)
      }
    });
    builder.addCase(fetchFriendSent.fulfilled, (state, action) => {
      if (action.payload) {
        state.friendSent = action.payload || [];

        //console.log(action.payload)
      }
    });
    builder.addCase(fetchInventory.fulfilled, (state, action) => {
      state.inventory = action.payload.inventory;
      state.total = action.payload.total;

      // console.log('fetchInventory.fulfilled', {
      // 	payload: action.payload,
      // 	state
      // })
    });
    builder.addCase(fetchMarketInventory.fulfilled, (state, action) => {
      state.marketInventory = action.payload.parsed;
      state.totalInv = action.payload.total;

      //console.log('fetchInventory.fulfilled', {
      // 	payload: action.payload,
      // 	state
      // })
    });
    builder.addCase(fetchServerInventory.fulfilled, (state, action) => {
      if (action.payload) {
        state.serverInventory = action.payload || [];

        //console.log(action.payload)
      }
    });
    builder.addCase(fetchInventoryToServer.fulfilled, (state, action) => {
      if (action.payload) {
        // state.inventory = state.inventory.filter((item) => item.skinInfo.item !== 3) //action.payload.skinInfo.item
        // skinSelectorState.skins = skinSelectorState.skins.filter((item) => item.skinInfo?.item !== 3)
        // state.inventory = state.inventory.filter((item, index) => index !== 2)
        // skinSelectorState.skins = skinSelectorState.skins.filter((item, index) => index !== 2)
        // state.serverInventory = action.payload || []
        // console.log(action.payload)
      }
    });
  },
});

export const { setAnotherUser, setUser, setInventory, filterMarketInventory } =
  userSlice.actions;
export const userActions = userSlice.actions;
export default userSlice.reducer;
