import { IInput } from "./../../../interfaces/Input";
import styled, { css } from "styled-components";
import ReactInputMask from "react-input-mask";

interface LabelProps {
  width?: string;
  color?: string;
}

export const Label = styled.label<LabelProps>`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  max-width: ${(props) => (props.width ? props.width : "30rem")};
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Text = styled.div<IInput>`
  padding-left: 2rem;
  ${(props) =>
    props.params?.headFont
      ? props.params.headFont
      : props.theme.fonts.GothamMedium24};
  align-self: ${(props) =>
    props.params?.labelPosition ? props.params?.labelPosition : "flex-start"};
  color: ${(props) =>
    props.params?.labelColor
      ? props.params.labelColor
      : props.theme.colors.white.white100};
`;

export const InputBlock = styled(ReactInputMask)<IInput>`
  ${(props) =>
    props.params?.font
      ? props.params?.font
      : props.theme.fonts.GothamRegular20};
  color: ${(props) =>
    props.params?.inputColor
      ? props.params.inputColor
      : props.theme.colors.white.white100};
  outline: none;
  border-radius: ${(props) =>
    props?.params?.bradius ? props?.params?.bradius : "2rem"};
  border: 0.1rem solid ${(props) => props.theme.colors.black.black30};
  background: ${(props) =>
    props.params?.background
      ? props.params?.background
      : props.theme.colors.white.white10};
  padding: ${(props) =>
    props.pad == true ? "0px 1rem 0 2rem" : "0px 6rem 0 2rem"};
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-right: 6rem; */
  height: ${(props) =>
    props?.params?.height ? props?.params?.height : "6rem"};
  transition: 0.3s all;
  ${(props) =>
    props.params?.InputPlaceholderColor &&
    css`
      &::placeholder {
        color: ${props?.params?.InputPlaceholderColor};
      }
    `}
  &:hover {
    border: 0.1rem solid ${(props) => props.theme.colors.blue.active};
  }
  &:focus {
    border: 0.1rem solid ${(props) => props.theme.colors.blue.hover};
  }
`;
export const ComareWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.9fr 0.2fr 0.9fr;
  width: 100%;
  border: 2px solid ${(props) => props.theme.colors.black.black30};
  border-radius: 2rem;
  height: 6rem;
  color: black;
`;
export const ComareInput = styled.input`
  font-family: inherit;
  ${(props) => props.theme.fonts.GothamBold20};
  width: 100%;
  height: 100%;
  color: black;
  background: transparent;
  border: none;
  outline: none;
  text-align: center;
`;
export const ComareMax = styled.span`
  ${(props) => props.theme.fonts.GothamBold20};
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
`;
