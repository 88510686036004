import styled, { css } from "styled-components";

export const BirthdayWrapper = styled.div`
	width: 100%;
	height: 5rem;
	padding: 1.5rem 2.5rem;
	background: ${props => props.theme.colors.white.white10};
	${props => props.theme.stroke.stroke5};
	border-radius: 2rem;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	align-items: center;
`

export const Wrapper = styled.div<{ hide?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	position: relative;
	${props => props.hide !== true && css`
		&::after {
			content: "";
			background: ${props => props.theme.colors.white.white10};
			width: .1rem;
			height: 2rem;
			position: absolute;
			right: 0;
		}
	`}
`