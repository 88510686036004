import { ISelect } from "interfaces/Select";
import { useEffect, useState } from "react";
import Body from "./Body";
import Head from "./Head";
import { Label, SelectBlock, Text, Trigger } from "./Styles";

export default function Select({
  params,
  items,
  onChange,
  label,
  defaultValue = "",
  Rating,
}: ISelect) {
  const [show, setShow] = useState<boolean>(false);
  const [headLabel, setHeadLabel] = useState<string>("");
  const [defVal, setDefVal] = useState<string>(defaultValue);

  useEffect(() => {
    if (Rating) {
      const label = items[0] ? items[0]?.label : items[0]?.label;
      setHeadLabel(label);
    }
  }, [items]);

  function onItemClick(index: number) {
    setShow(false);

    const label = items[index]?.label ? items[index]?.label : items[index];
    const value = items[index]?.value ? items[index]?.value : items[index];

    setHeadLabel(label);
    setDefVal("");
    onChange(value);
  }

  return (
    <>
      {show && (
        <Trigger
          onClick={() => setShow(false)}
          className={show ? "active" : ""}
        />
      )}
      <Label width={params?.width}>
        {label && <Text font={params?.font}>{label}</Text>}
        <SelectBlock>
          <Head
            position={params?.position}
            height={params?.headHeight}
            setShow={setShow}
            show={show}
            label={headLabel}
            defaultValue={defVal}
            variant={params?.variant}
            font={params?.headFont}
          />
          <Body
            show={show}
            items={items}
            onItemClick={onItemClick}
            variant={params?.variant}
            font={params?.bodyFont}
          />
        </SelectBlock>
      </Label>
    </>
  );
}
