import { SelectVariants } from "interfaces/Select";
import styled, { css } from "styled-components";

interface Props {
  height?: string;
  position?: "left" | "center" | "right";
  variant?: SelectVariants;
}

export const HeadBlock = styled.div<Props>`
  position: relative;
  /* z-index: 3; */
  user-select: none;
  width: 100%;
  cursor: pointer;
  height: ${(props) => (props.height ? props.height : "6rem")};
  border-radius: 2rem;
  background: ${(props) => props.theme.colors.white.white10};
  border: 0.1rem solid ${(props) => props.theme.stroke.stroke5};
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  transition: 0.3s all;
  svg {
    transition: all 0.3s;
    transform: rotate(180deg);
  }
  &:hover {
    border: 0.1rem solid ${(props) => props.theme.colors.blue.active};
  }
  &.active {
    svg {
      transform: rotate(0deg);
    }
    border: 0.1rem solid ${(props) => props.theme.colors.blue.hover};
  }
  ${(props) =>
    props.variant === "transparent" &&
    css`
      background: transparent;
      border: none;
      height: fit-content;
      padding: 0 !important;
      &:hover {
        border: none;
      }
      &.active {
        border: none;
      }
    `}
  ${(props) =>
    props.position === "left" &&
    css`
      justify-content: flex-start;
    `}
	${(props) =>
    props.position === "center" &&
    css`
      justify-content: center;
    `}
	${(props) =>
    props.position === "right" &&
    css`
      justify-content: flex-end;
    `}
`;

export const Text = styled.div<{ font?: string }>`
  ${(props) => (props.font ? props.font : props.theme.fonts.GothamMedium20)};
  color: ${(props) => props.theme.colors.white.white100};
`;
