import { url } from "API";
import axios from "axios";

export class CaseServise {
  static async getCases() {
    try {
      const response = await axios.get(url + "/cases", {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }
  static async getItemCase(id: number) {
    try {
      const response = await axios.get(url + "/cases/case/" + id, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }
  static async OpenCase(id: number, count: number) {
    try {
      const response = await axios.post(
        url + "/cases/open",
        {
          id: id,
          count: count,
        },
        {
          withCredentials: true,
        }
      );
      // console.log(response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          // Обработка ошибок с ответом от сервера
          return error.response.data.delay;
        }
      }
    }
  }
  static async SoldItem(item_id: number[]) {
    try {
      const response = await axios.post(
        url + "/cases/saleNow",
        { item_id: item_id },
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }
  static async GetLastTenItem() {
    try {
      const response = await axios.get(url + "/cases/live", {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }
}
