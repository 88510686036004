import { useRef } from 'react'
import { Wrapper } from './Styles';

interface Props {
	setFile?: React.SetStateAction<any>;
	accept?: string;
	children: any;
	multiple?: boolean;
	files?: File[];
}

export default function FileUpload({ children, setFile, accept, multiple, files = [] }: Props) {

	const ref = useRef<HTMLInputElement>(null)

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {

		if (multiple) {
			setFile([...files, event.target.files?.[0]])
		} else {
			setFile(event.target.files?.[0] || null)
		}
	}

	return (
		<Wrapper onClick={() => ref.current?.click()}>
			<input
				type="file"
				accept={accept}
				ref={ref}
				onChange={onChange}
				multiple={multiple}
			/>
			{children}
		</Wrapper>
	)
}
