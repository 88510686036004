import API from "API";
import { SocketProvider } from "API/SocketProvider";
import { UserService } from "API/UserService";
import { VipServise } from "API/VipServise";
import { Icons } from "assets/icons";
import { TinyIcons } from "assets/tiny-icons";
import { ProfileCard } from "components/Cards";
import ModalAdminChange from "components/Modal/ModalAdminChange/ModalAdminChange";
import VipModalGift from "components/Modal/VipModalGift/VipModal";
import Tabs from "components/Templates/Tabs";
import Button from "components/UI/Button";
import { ProfileContext } from "context/profile";
import { useActions } from "hooks/useActions";
import { useAppSelector } from "hooks/useAppSelector";
import { GetCatecory } from "interfaces/Vip";
import { memo, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isYou } from "utils/IsYou";
import {
  AdditionalButtons,
  TabsWrapper,
  Wrapper,
  WrapperFlexBase,
} from "./Styles";

interface Props {
  children?: any;
  gap?: string;
}

function ProfileLayout({ children, gap }: Props) {
  const { tab, steamid64 = "" } = useParams();
  const { user, anotherUser, personalFriends, userData, isAuth } =
    useAppSelector((state) => state?.user);
  const [adminChange, setAdminChange] = useState<GetCatecory[]>([]);

  const [ActiveModalBuy, setActiveModalBuy] = useState<boolean>(false);
  const [ActiveModalDELETE, setActiveModalDELETE] = useState<boolean>(false);
  const [VipInfo, setVipInfo] = useState<GetCatecory[]>([]);
  const [VP, setVP] = useState<number>(0);

  const { setAnotherUser, setUserData } = useActions();
  const ISYOU = isYou(steamid64);
  const friendCondition = !personalFriends.find(
    (friend) => friend.steamid64 !== steamid64
  );
  const navigate = useNavigate();
  const [tradeModal, setTradeModal] = useState(false);

  useMemo(() => {
    if (steamid64 !== user?.user?.steamid64) {
      (async () => {
        const anotherUser = await API.UserService.getAnotherProfile(steamid64);
        setAnotherUser(anotherUser.data);
      })();
    }
    async function getVip() {
      if (!user?.group?.flags.includes("h")) {
        const result = await VipServise.GetShop();
        setVipInfo(result);
      }
    }
    getVip();
  }, [steamid64]);

  useMemo(() => {
    if (userData) {
      if (userData?.group?.flags.includes("v")) {
        setVP(0);
      } else if (userData?.group?.flags.includes("p")) {
        setVP(1);
      } else {
        setVP(0);
      }
    }
  }, [userData]);

  useMemo(() => {
    if (user?.user?.steamid64 !== steamid64) {
      setUserData(anotherUser);
    } else {
      setUserData(user);
    }
  }, [user, anotherUser, steamid64]);

  function handleTabs(setTab: (index: number) => void) {
    if (typeof setTab !== "function") return;

    switch (tab) {
      case "inventory":
        setTab(1);
        break;
      case "skinchanger":
        setTab(3);
        break;
      case "privileges":
        setTab(2);
        break;
      case "friends":
        setTab(4);
        break;
      case "messages":
        setTab(5);
        break;
      case "notifications":
        setTab(6);
        break;
      case "settings":
        setTab(7);
        break;
      case "applications":
        setTab(8);
        break;

      default:
        setTab(0);
        break;
    }
  }

  const profileTabItems = ISYOU
    ? [
        { href: `/profile/${userData?.user?.steamid64}`, label: "Профиль" },
        {
          href: `/profile/${userData?.user?.steamid64}/inventory`,
          label: "Инвентарь",
        },
        {
          href: `/profile/${userData?.user?.steamid64}/privileges`,
          label: "Привилегии",
        },
        {
          href: `/profile/${user?.user?.steamid64}/skinchanger`,
          label: "Управление снаряжением",
        },

        // {
        //   href: `/profile/${userData?.user?.steamid64}/friends`,
        //   label: "Друзья",
        // },
        // {
        //   href: `/profile/${userData?.user?.steamid64}/messages`,
        //   label: "Сообщения",
        // },
        // {
        //   href: `/profile/${userData?.user?.steamid64}/notifications`,
        //   label: "Уведомления",
        // },
        // {
        //   href: `/profile/${userData?.user?.steamid64}/settings`,
        //   label: "Настройки",
        // },
        // {
        //   href: `/profile/${userData?.user?.steamid64}/applications`,
        //   label: "Наказания",
        // },
      ]
    : [
        { href: `/profile/${userData?.user?.steamid64}`, label: "Профиль" },
        {
          href: `/profile/${userData?.user?.steamid64}/inventory`,
          label: "Инвентарь",
        },
        {
          href: `/profile/${userData?.user?.steamid64}/privileges`,
          label: "Привилегии",
        },
        // {
        //   href: `/profile/${userData?.user?.steamid64}/friends`,
        //   label: "Друзья",
        // },
      ];

  // function privateChatHandler() {
  //   navigate(`/chat/${userData?.user?.steamid64}`);
  // }

  const toggleTradeModal = () => {
    const socketProvider = new SocketProvider();
    // console.log("steamid64", steamid64);
    socketProvider.sendTrade(steamid64);
  };

  function addFriend() {
    UserService.addFriend(steamid64);
  }

  // console.log(VipInfo);
  return (
    <Wrapper gap={gap}>
      {/* <Modals.Trade
				isActive={tradeModal}
				setActive={setTradeModal}
			/> */}

      <ProfileCard />
      {!ISYOU && isAuth && (
        <WrapperFlexBase>
          <AdditionalButtons>
            {/* <Button
              height="5rem"
              variant="default"
              color="blue"
              onClick={addFriend}
              disabled={friendCondition}
            >
              <TinyIcons.PlusWhite /> Добавить в друзья
            </Button> */}
            {/* <Button
              height="5rem"
              variant="default"
              color="grayy"
              onClick={privateChatHandler}
              disabled={true}
            >
              <TinyIcons.Chat />
            </Button> */}
            <Button
              height="5rem"
              variant="default"
              color="blue"
              onClick={toggleTradeModal}
              // params={{ width: "6.5rem" }}
            >
              <Icons.Swap />
              Предложить обмен
            </Button>

            {/* <Button height="5rem" variant="default" color="red">
            Пожаловаться
          </Button> */}
            {/* <Button
              height="5rem"
              variant="default"
              color="grayy"
              disabled={true}
            >
              <TinyIcons.BlockPlayer />
            </Button> */}
          </AdditionalButtons>
          {user?.group?.flags.includes("h") ? (
            <Button
              variant="default"
              height="5rem"
              color="blueVIP"
              onClick={async () => {
                setActiveModalDELETE(true);
                const result = await VipServise.GetAdmins();
                if (result) {
                  setAdminChange(result);
                }
              }}
            >
              <Icons.Cart />
              Управление игроком
            </Button>
          ) : userData?.group?.name == "VIP" ||
            userData?.group?.name == "PREMIUM" ? (
            <Button
              variant="default"
              height="5rem"
              color="blueVIP"
              onClick={() => setActiveModalBuy(true)}
            >
              <TinyIcons.Crown /> Продлить подписку
            </Button>
          ) : userData?.group?.flags.includes("u") ? (
            <AdditionalButtons>
              <Button
                variant="default"
                height="5rem"
                color="blueVIP"
                onClick={() => {
                  setActiveModalBuy(true);
                  setVP(0);
                }}
              >
                <Icons.Cart /> Подарить VIP
              </Button>
              <Button
                variant="default"
                height="5rem"
                color="orangeVIP"
                onClick={() => {
                  setActiveModalBuy(true);
                  setVP(1);
                }}
              >
                <Icons.Cart />
                Подарить PREMIUM
              </Button>
            </AdditionalButtons>
          ) : null}
        </WrapperFlexBase>
      )}
      {VipInfo && (
        <VipModalGift
          isActive={ActiveModalBuy}
          setActive={setActiveModalBuy}
          vip={VipInfo[VP]}
          userData={userData}
        />
      )}
      {adminChange && (
        <ModalAdminChange
          isActive={ActiveModalDELETE}
          setActive={setActiveModalDELETE}
          AdminChange={adminChange}
          userDate={userData}
        />
      )}
      <TabsWrapper isYou={ISYOU}>
        <Tabs
          callback={handleTabs}
          type="link"
          context={ProfileContext}
          items={profileTabItems}
        />
      </TabsWrapper>
      {children}
    </Wrapper>
  );
}

export default memo(ProfileLayout);
