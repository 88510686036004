import { Button } from "components/UI/Button/Styles";
import { motion } from "framer-motion";
import { InputBlock as InputStyles } from "components/UI/Input/Styles";
import styled, { css } from "styled-components";
import { ChatParams } from "models/Chat";

export const Container = styled(motion.form)<{
  params?: ChatParams;
  activ?: Boolean;
}>`
  /* max-height: ${({ params }) =>
    params?.height ? params.height : "100vh"}; */
  height: 100vh;
  max-width: ${({ params }) => (params?.width ? params.width : "95rem")};
  width: 100%;
  /* background: red; */
  padding: 2rem 0.5rem 2rem 2rem;
  background: ${(props) => props.theme.colors.black.black100};
  backdrop-filter: blur(100px);
  align-items: end;
  border-radius: 2.5rem 0 0 2.5rem;
  z-index: 1000000;
  display: ${(props) => (props.activ ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-between;
  /* gap: 2rem; */
  border: 0.3rem solid ${(props) => props.theme.stroke.stroke5};
  position: relative;
  right: -3rem;
  ${(props) => props.theme.shadows.defaultShadow} /* img {
    height: 300px;
    width: 300px;
  } */
  svg {
    cursor: pointer;
    transition: 0.3s all;
    min-height: 20px;
    min-width: 20px;
    &:hover {
      opacity: 0.5;
    }
  }
`;

export const Header = styled.div``;

export const Messages = styled.div`
  display: flex;
  flex-direction: column;
  /* padding-right: 1rem; */
  gap: 2rem;
  max-height: calc(90% - 2rem);
`;

export const InputBlock = styled.div<{ isFileInput: boolean }>`
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* height: 10rem; */
  position: relative;
  width: 100%;
  /* align-self: flex-end; */
  /* align-items: center; */
  /* ${InputStyles} {
    padding-right: ${(props) => (props.isFileInput ? "14rem" : "10rem")};
  } */
`;

export const Buttons = styled.div`
  position: absolute;
  right: 3.5rem;
  top: 2.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  ${Button} {
    width: fit-content;
    height: fit-content;
    /* padding: 0.6rem; */
  }
`;

export const ChatButton = styled.button<{ variant: "gray" | "blue" }>`
  border-radius: 50%;
  padding: 0.6rem;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
  ${({ variant }) =>
    variant === "gray" &&
    css`
      background: ${({ theme }) => theme.colors.white.white10};
    `}
  ${(props) =>
    props.variant === "blue" &&
    css`
      background: ${({ theme }) => theme.colors.blue.accent};
      &:hover {
        background: ${({ theme }) => theme.colors.blue.hover};
      }
      &:active {
        background: ${({ theme }) => theme.colors.blue.active};
      }
    `}
`;

export const EmojiWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 6rem;
`;

export const EmptyMessages = styled.p`
  display: block;
  margin: 0 auto;
  text-align: center;
  ${({ theme }) => theme.fonts.GothamMedium32};
  color: ${({ theme }) => theme.colors.white.white100};
  span {
    color: ${({ theme }) => theme.colors.blue.accent};
  }
`;

export const DefSpan = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OnlineDiv = styled.div`
  ${(props) => props.theme.fonts.GothamMedium15}
  color:white;
  display: flex;
  align-items: center;
`;

export const AttachedFilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Title = styled.h3`
  ${({ theme }) => theme.fonts.GothamRegular16};
  color: ${({ theme }) => theme.colors.white.white100};
`;

export const AttachedFiles = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
  flex-wrap: wrap;
`;

export const ImageWrapper = styled.div`
  position: relative;
  svg {
    width: 5rem;
    height: 5rem;
    position: absolute;
    transition: 0.15s all;
    cursor: pointer;
    top: -2.5rem;
    right: -2.5rem;
    &:hover {
      opacity: 0.7;
    }
  }
  img {
    width: 15rem;
    object-fit: cover;
    height: 15rem;
  }
`;
