import { endpoints, url } from "API";
import axios from "axios";

export class ShopService {
  static async getShop() {
    try {
      const response = await axios.get(url + endpoints.shop.get);
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async BuyMoney(amount, mail, FreeKassaType) {
    try {
      const response = await axios.get(
        FreeKassaType !== "Default"
          ? url +
              endpoints.shop.get +
              endpoints.shop.freeKassa +
              "/new/" +
              amount +
              "/" +
              FreeKassaType
          : url + endpoints.shop.get + endpoints.shop.freeKassa + "/" + amount,
        {
          headers: {
            Authorization: "Bearer " + (await this.getAuthToken()),
          },
          withCredentials: true,
        }
      );
      const cleanUrl = response.data.data.url;
      window.location.href = cleanUrl;
      // console.log(cleanUrl);
      return response.data.success;
    } catch (error) {
      // console.log(error);
    }
  }
  static async BuyUnitpay(email, amount, paymentType) {
    try {
      const response = await axios.post(
        url + endpoints.shop.get + "/" + endpoints.shop.unit_pay,
        {
          email: email,
          amount: amount,
          paymentType: paymentType,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + (await this.getAuthToken()),
          },
        }
      );

      const cleanUrl = response.data.url;
      // console.log(cleanUrl);
      window.location.href = cleanUrl;
      // console.log(cleanUrl);
      return response.data.success;
    } catch (error) {
      // console.log(error);
    }
  }

  static async getCredits() {
    try {
      const response = await axios.get(
        url + endpoints.shop.get + endpoints.shop.credits,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }

  static async postCredits(id) {
    try {
      const response = await axios.post(
        url + endpoints.shop.get + endpoints.shop.credits,
        { id },
        {
          headers: {
            Authorization: "Bearer " + (await this.getAuthToken()),
          },
          withCredentials: true,
        }
      );
      // console.log("response", response.data);
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }

  static async getAuthToken() {
    try {
      const response = await axios.get(url + endpoints.skinchanger.authToken, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error("Error in getAuthToken:", error.message);
      throw error;
    }
  }
}
